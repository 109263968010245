export type EformsState = {
  eformsUrl: string;
  eformsToken: string;
};

export const initialEformsState: EformsState = {
  eformsUrl: '',
  eformsToken: '',
};

// The following enums are events emitted by the embedded realtime forms
export enum PreviousSubmissionStatus {
  AllSubmitted = 'AllSubmitted',
  NoneSubmitted = 'NoneSubmitted',
  SomeSubmitted = 'SomeSubmitted',
  NoFormsForPatient = 'NoFormsForPatient',
}

export type LoadFormsSubmittedResponse = {
  previousSubmissionStatus: PreviousSubmissionStatus;
};

export type LoadFormsCompleteResponse = {
  allFormsSubmitted: boolean;
};
