import { DEFAULT_IDLE_TIMEOUT_IN_SECONDS, DEFAULT_TIME_BEFORE_IDLE_IN_SECONDS } from '@abbadox-monorepo/core-constants';

export interface IdleState {
  setup: boolean;
  idleBeforeTimeoutSeconds: number;
  idleTimeoutSeconds: number;
  interruptsSet: boolean;
  running: boolean;
}

export const initialIdleState: IdleState = {
  setup: false,
  idleBeforeTimeoutSeconds: DEFAULT_TIME_BEFORE_IDLE_IN_SECONDS,
  idleTimeoutSeconds: DEFAULT_IDLE_TIMEOUT_IN_SECONDS,
  interruptsSet: false,
  running: false,
};
