<div class="relative" [formGroup]="formGroup()">
  <input
    class="ids-form-control"
    [tabIndex]="tabindex()"
    [type]="type()"
    [placeholder]="placeholder()"
    [formControlName]="formControlName()"
    bsDatepicker
    #dp="bsDatepicker"
    (bsValueChange)="onValueChange($event)"
    [triggers]="triggers()"
    [isOpen]="isOpen()"
    [bsConfig]="_config()"
    [disabled]="disabled()"
    maxlength="10"
  />
  <button class="flex cursor-pointer" [tabIndex]="iconTabindex()" #abicon>
    <i idsIcon icon="calendar"></i>
  </button>
</div>
