import { CommentType } from '@abbadox-monorepo/kiosk-core-api-interfaces';

export type Patient = {
  autoCount: number;
  mrn: string;
  patientFirst: string;
  patientMiddle: string;
  patientLast: string;
  sex: string;
  ptDOB: string;
  phone: string;
  mobilePhone: string;
  email: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
};

export const defaultPatient: Patient = {
  autoCount: 0,
  mrn: '',
  patientFirst: '',
  patientMiddle: '',
  patientLast: '',
  sex: '',
  ptDOB: '',
  phone: '',
  mobilePhone: '',
  email: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zipcode: '',
};

export interface PatientsState {
  duplicate: boolean;
  attempts: number;
  filters: Filters;
  sessionStarted: boolean;
}

export const initialPatientsState: PatientsState = {
  duplicate: false,
  attempts: 0, // only legitimate, successful attempts should be tracked. API failures don't count
  filters: {
    params: {},
    valid: false,
  },
  sessionStarted: false,
};

export type Filters = {
  params: PatientSearchParams;
  valid: boolean;
};

export type PatientSearchParams = Record<string, string>;

export type PatientAppointmentComments = {
  comments: Record<CommentType, string>;
};

export const initialPatientAppointmentComments: PatientAppointmentComments = {
  comments: {
    patient: '',
    appointment: '',
  },
};
