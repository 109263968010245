import { inject } from '@angular/core';

import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { withState, signalStore, withMethods, patchState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { filter, merge, pipe, switchMap, tap } from 'rxjs';

import { DEFAULT_TIME_BEFORE_IDLE_IN_SECONDS } from '@abbadox-monorepo/core-constants';
import { withLogger } from '@abbadox-monorepo/core-data-access';

import { initialIdleState } from './models/idle.models';
import { setIdleActive, setIdleExpired, setIdleIdling, setIdleInit, withIdleStatus } from './with-idle-status.feature';

export const IdleStore = signalStore(
  { providedIn: 'root' },
  withState(initialIdleState),
  withIdleStatus(),
  withLogger('idle'),
  withMethods((state, idle = inject(Idle)) => {
    function setIdle(idleBeforeTimeoutSeconds = DEFAULT_TIME_BEFORE_IDLE_IN_SECONDS) {
      idle.setIdle(idleBeforeTimeoutSeconds);
      patchState(state, { idleBeforeTimeoutSeconds });
    }

    function setTimeout(idleTimeoutSeconds: number) {
      idle.setTimeout(idleTimeoutSeconds);
      patchState(state, { idleTimeoutSeconds });
    }

    function setInterrupts() {
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      patchState(state, { interruptsSet: true });
    }

    const setEventListeners = rxMethod<void>(
      pipe(
        filter(() => !state.setup()),
        switchMap(() => {
          const onIdleStart$ = idle.onIdleStart.pipe(tap(() => patchState(state, setIdleIdling)));
          const onIdleEnd$ = idle.onIdleEnd.pipe(tap(() => patchState(state, setIdleActive)));
          const onTimeout$ = idle.onTimeout.pipe(tap(() => patchState(state, setIdleExpired)));

          return merge(onIdleStart$, onIdleEnd$, onTimeout$);
        }),
      ),
    );

    function setupIdleTimeout(idleTimeoutSeconds: number) {
      if (!state.setup()) {
        setIdle();
        setTimeout(idleTimeoutSeconds);
        setInterrupts();
        setEventListeners();
        patchState(state, { setup: true });
      }
    }

    function startIdling(): void {
      if (idle.isRunning()) {
        idle.stop();
      }

      idle.watch();
      patchState(state, { running: true });
    }

    function stopIdling(): void {
      idle.stop();
      patchState(state, { running: false });
    }

    function resetIdle() {
      idle.stop();
      idle.clearInterrupts();
      patchState(state, { interruptsSet: false, running: false, setup: false }, setIdleInit);
    }

    return { setupIdleTimeout, startIdling, stopIdling, resetIdle };
  }),
);
