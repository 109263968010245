import { inject } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  mergeMapHubToAction,
  startSignalRHub,
  signalrHubUnstarted,
  signalrConnected,
  findHub,
  hubNotFound,
  createSignalRHub,
  stopSignalRHub,
} from 'ngrx-signalr-core';
import { catchError, map, merge, mergeMap, of, withLatestFrom } from 'rxjs';

import {
  JOIN_GROUP_FOR_APP_CHANNEL_KEY,
  RECEIVE_ALL_FORMS_SUBMITTED_NOTIFICATION,
  RECEIVE_PREVIOUS_FORMS_SUBMISSION_STATUS,
} from '@abbadox-monorepo/core-constants';
import { SIGNLAR_FORMS_HUB_CONFIG } from '@abbadox-monorepo/core-real-time-client';

import { RealtimeFormsActions, selectEformsToken } from './eforms-signalr.state';
import { LoadFormsCompleteResponse, LoadFormsSubmittedResponse } from './models/realtime-forms.models';

/** Creates real time forms connection from hub. */
export const createRealtimeFormsConnection$ = createEffect(
  (actions$ = inject(Actions), formsHub = inject(SIGNLAR_FORMS_HUB_CONFIG)) =>
    actions$.pipe(
      ofType(RealtimeFormsActions.loadExtractedCredentials),
      map(({ eformsToken }) =>
        createSignalRHub({
          ...formsHub,
          options: {
            withCredentials: false,
            accessTokenFactory() {
              return eformsToken;
            },
          },
        }),
      ),
    ),
  { functional: true },
);

/** Initialoze SignalR service for a hub. */
export const initRealtimeForms$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(signalrHubUnstarted),
      map((hub) => startSignalRHub(hub)),
    ),
  { functional: true },
);

/** Listens for realtime forms events. */
export const listenFormsEvents$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(signalrConnected),
      mergeMapHubToAction(({ hub }) => {
        const whenFormsStatusUpdated$ = hub
          .on<LoadFormsSubmittedResponse>(RECEIVE_PREVIOUS_FORMS_SUBMISSION_STATUS)
          .pipe(
            map(({ previousSubmissionStatus }) =>
              RealtimeFormsActions.loadFormsSubmittedStatus({ formsSubmittedStatus: previousSubmissionStatus }),
            ),
          );

        const whenFormsCompleted$ = hub
          .on<LoadFormsCompleteResponse>(RECEIVE_ALL_FORMS_SUBMITTED_NOTIFICATION)
          .pipe(
            map(({ allFormsSubmitted }) =>
              RealtimeFormsActions.loadFormsCompletedStatus({ formsCompleted: allFormsSubmitted }),
            ),
          );

        return merge(whenFormsStatusUpdated$, whenFormsCompleted$);
      }),
    ),
  { functional: true },
);

export const invokeFormsChannel$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(signalrConnected),
      withLatestFrom(store.select(selectEformsToken)),
      mergeMap(([formsHub, token]) => {
        const hub = findHub(formsHub);

        if (!hub) {
          return of(hubNotFound(formsHub));
        }

        return hub.send(JOIN_GROUP_FOR_APP_CHANNEL_KEY, token).pipe(
          map(() => RealtimeFormsActions.connectToRealtimeFormsChannelSuccess()),
          catchError((error) => of(RealtimeFormsActions.connectToRealtimeFormsChannelFailed(error))),
        );
      }),
    ),
  { functional: true },
);

export const stopRealtimeConnection$ = createEffect(
  (actions$ = inject(Actions), formsHub = inject(SIGNLAR_FORMS_HUB_CONFIG)) =>
    actions$.pipe(
      ofType(RealtimeFormsActions.connectToRealtimeFormsChannelStopped),
      map(() => stopSignalRHub(formsHub)),
    ),
  { functional: true },
);
