import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { removeAllEntities, SelectEntityId, setAllEntities, withEntities } from '@ngrx/signals/entities';

import {
  setError,
  setLoaded,
  setLoading,
  withLogger,
  withRequestStatus,
  withSelectedEntity,
} from '@abbadox-monorepo/core-data-access';
import { FormsService } from '@abbadox-monorepo/core-forms';
import { hasAllValues } from '@abbadox-monorepo/core-utils';
import { TOAST_DISMISS_DURATION_IN_MILLISECONDS } from '@abbadox-monorepo/kiosk-core-constants';
import {
  KioskCheckinFailedDialog,
  KioskDialogData,
  KioskPatientCheckinFailedDialogData,
  KioskPatientNotFoundDialog,
  PATIENT_CHECKIN_FAILED_DIALOG_TRIGGERS,
} from '@abbadox-monorepo/kiosk-ui';
import {
  defaultWidget,
  KioskConfigurationsStore,
  WIDGET_NAMES,
  WORKFLOW_NAMES,
} from '@abbadox-monorepo/kiosk-workflows-data-access';
import { IdsNotificationComponent, IdsToast, notificationStatusColorMap } from '@abbadox-monorepo/shared-ui';

import { initialPatientsState, Patient, PatientSearchParams } from './models/patient.model';
import { parsePatient } from './patient.parser';
import { PatientsHttpService } from './services/patients-http-client.service';

const selectId: SelectEntityId<Patient> = (patient) => patient.autoCount;

export const PatientsSearchStore = signalStore(
  { providedIn: 'root' },
  withState(initialPatientsState),
  withEntities<Patient>(),
  withSelectedEntity(),
  withRequestStatus(),
  withLogger('patients search'),
  withComputed((_, kioskConfigurationsStore = inject(KioskConfigurationsStore)) => {
    const patientWidget = computed(
      () =>
        kioskConfigurationsStore
          .stepWidgets()
          .find((sw) => new RegExp(WIDGET_NAMES.AUTHENTICATION, 'i').test(sw.widget.widgetName)) ?? defaultWidget,
    );
    const failureMessage = computed(
      () => patientWidget().widget.failureMessage ?? patientWidget().failureMessageOverride,
    );

    return { patientWidget, failureMessage };
  }),
  withMethods(
    (
      state,
      patientsHttpService = inject(PatientsHttpService),
      kioskConfigurationsStore = inject(KioskConfigurationsStore),
      formsService = inject(FormsService),
      dialog = inject(MatDialog),
      toast = inject(IdsToast),
    ) => {
      function resetAttempts(): void {
        patchState(state, { attempts: 0 });
      }

      function resetPatientSearchState(): void {
        patchState(state, removeAllEntities(), { ...initialPatientsState, selectedEntityId: null });
      }

      function showErrorNotificationToast(): void {
        toast.openFromComponent(IdsNotificationComponent, {
          data: {
            ...notificationStatusColorMap.get('error'),
            label: 'No Match Found',
            messages: [state.failureMessage()],
          },
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: TOAST_DISMISS_DURATION_IN_MILLISECONDS,
        });
      }

      function showNoPatientFoundDialog(): void {
        dialog.open<KioskPatientNotFoundDialog, KioskDialogData>(KioskPatientNotFoundDialog, {
          data: { title: 'No Existing Patient Found' },
        });
      }

      function showFailedAttemptsDialog(): void {
        dialog
          .open<KioskCheckinFailedDialog, KioskPatientCheckinFailedDialogData>(KioskCheckinFailedDialog, {
            data: {
              title: 'Authentication Failed',
              maxTimeout: kioskConfigurationsStore.authRetrySessionTimeoutInSeconds(),
              maxAttempts: kioskConfigurationsStore.numberOfAuthRetryAttempts(),
              sessionTimeout: kioskConfigurationsStore.sessionTimeoutInSeconds(),
            },
          })
          .afterClosed()
          .subscribe((event) => {
            if (
              [
                PATIENT_CHECKIN_FAILED_DIALOG_TRIGGERS.CLOSE_BUTTON,
                PATIENT_CHECKIN_FAILED_DIALOG_TRIGGERS.TIMEOUT,
              ].includes(event)
            ) {
              resetPatientSearchState();
              kioskConfigurationsStore.resetWorkflow();
            }
          });
      }

      function updateSearchParams(params: PatientSearchParams): void {
        patchState(state, {
          filters: {
            ...state.filters(),
            params: {
              ...state.filters.params(),
              ...params,
            },
          },
        });
      }

      function checkFormValidation(): void {
        const form = formsService.getForm();

        if (form) {
          formsService.validateFormControls();
          patchState(state, { filters: { ...state.filters(), valid: form.valid } });
        }
      }

      async function searchPatients(): Promise<void> {
        const params = state.filters().params;

        if (!state.filters().valid && !hasAllValues(state.filters().params)) {
          return;
        }

        patchState(state, setLoading());

        try {
          const { patient, duplicate } = await patientsHttpService.searchPatientsAsPromise(params);
          const parsedPatient = parsePatient(patient);

          if (!patient) {
            patchState(state, { duplicate, sessionStarted: true, attempts: 0 }, setLoaded());
          } else {
            patchState(
              state,
              setAllEntities([parsedPatient], { selectId }),
              { selectedEntityId: parsedPatient.autoCount, duplicate, sessionStarted: true, attempts: 0 },
              setLoaded(),
            );
          }
        } catch (err) {
          const error = err as HttpErrorResponse;
          patchState(state, { attempts: state.attempts() + 1 }, setError(error.message));

          const workflow = kioskConfigurationsStore.selectedEntityId();

          if (state.attempts() < kioskConfigurationsStore.numberOfAuthRetryAttempts()) {
            if (kioskConfigurationsStore.selectedEntityId() === WORKFLOW_NAMES.CHECK_IN) {
              showErrorNotificationToast();
            } else if (workflow === WORKFLOW_NAMES.WALK_IN) {
              showNoPatientFoundDialog();
            }
          } else if (state.attempts() === kioskConfigurationsStore.numberOfAuthRetryAttempts()) {
            showFailedAttemptsDialog();
          }
        }
      }

      async function validateAndSearchPatients(): Promise<void> {
        checkFormValidation();
        await searchPatients();
      }

      return {
        updateSearchParams,
        resetAttempts,
        resetPatientSearchState,
        validateAndSearchPatients,
      };
    },
  ),
);
