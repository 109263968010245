import { OutputFileEntry } from '@uploadcare/file-uploader';

import { UploadSecureSignature } from '@abbadox-monorepo/core-utils';

export type UploadFile = OutputFileEntry<'success'>;

export type FilesState = {
  file: string | null;
  secureParams: UploadSecureSignature;
};

export const initialFilesState: FilesState = {
  file: null,
  secureParams: {
    secureSignature: '',
    secureExpire: '',
  },
};
