import { KeyValuePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { ErrorMapperPipe } from './error-mapper.pipe';
import { IsErrorVisibleDirective } from './is-error-visible.directive';

@Component({
  selector: 'core-forms-input-errors',
  standalone: true,
  imports: [KeyValuePipe, ErrorMapperPipe, IsErrorVisibleDirective],
  template: `
    <div *isErrorVisible="control()">
      <ul class="error-messages">
        @for (error of control().errors | keyvalue; track error) {
          <li data-e2e-id="error" class="text-critical">
            <small>{{ error.key | errorMapper: error.value }}</small>
          </li>
        }
      </ul>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormsInputErrors {
  readonly control = input.required<AbstractControl>();
}
