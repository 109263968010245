import { hyphenate } from '@abbadox-monorepo/core-utils';
import { KioskConfiguration } from '@abbadox-monorepo/kiosk-core-api-interfaces';

import { KioskConfigurations } from './models/configs.model';
import { WIDGET_NAMES } from './models/widget.model';
import { Workflow } from './models/workflow.model';

export const parseConfigs = (config: KioskConfiguration): KioskConfigurations => {
  let workflows: Workflow[] = [];
  // reset step order to a manageable number
  let sortOrder = 0;

  const startScreenWidgets = config.startScreenWidgets.map((ssw) => ({
    ...ssw,
    startScreenWidgetSections: ssw.startScreenWidgetSections.map((ssws) => {
      workflows = ssws.workflows.map((w) => {
        // because the step order is reset, map the new value before sorting...
        const entities = w.steps
          .map((s) => {
            // ...then increment the order for the next step
            sortOrder++;
            return { ...s, sortOrder, route: hyphenate(s.stepName) };
          })
          .sort((a, b) => a.sortOrder - b.sortOrder);

        return {
          ...w,
          action: { ...w.action, route: hyphenate(w.action.actionName) },
          steps: { entities, total: w.steps.length },
        };
      });

      return {
        ...ssws,
        workflows,
      };
    }),
  }));

  return {
    ...config,
    startScreenWidgets,
    workflows,
  };
};

export const parseSkippedFeatures = (
  workflow: Workflow,
  validations: {
    idsUploaded: boolean;
    insuranceUploaded: boolean;
  },
): Workflow => {
  let sortOrder = 0;

  const entities = workflow.steps.entities
    .filter(
      (step) =>
        !step.stepWidgets.some(
          (sw) =>
            (sw.widget.widgetName === WIDGET_NAMES.UPLOAD_INDENTIFICATION_FRONT && validations.idsUploaded) ||
            (sw.widget.widgetName === WIDGET_NAMES.UPLOAD_INSURANCE_FRONT && validations.insuranceUploaded),
        ),
    )
    .map((step) => {
      sortOrder++;
      return { ...step, sortOrder };
    });

  return {
    ...workflow,
    steps: { entities, total: entities.length },
  };
};
