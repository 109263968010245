import { NgClass } from '@angular/common';
import { booleanAttribute, Component, input } from '@angular/core';

@Component({
  selector: 'ids-spinner-overlay',
  standalone: true,
  imports: [NgClass],
  template: `<div
    class="z-999 absolute flex h-full w-full items-center justify-center"
    [ngClass]="{ 'bg-alpha-5': overlay() }"
  >
    <ng-content></ng-content>
  </div>`,
})
export class IdsSpinnerOverlay {
  readonly overlay = input(false, { transform: booleanAttribute });
}
