// State - Idle
export const DEFAULT_TIME_BEFORE_IDLE_IN_SECONDS = 1; // immediately
export const DEFAULT_IDLE_TIMEOUT_IN_SECONDS = 60; // 1 minute

// SignalR
export const JOIN_GROUP_FOR_APP_CHANNEL_KEY = 'JoinGroupForApp';
export const RECEIVE_ALL_FORMS_SUBMITTED_NOTIFICATION = 'ReceiveAllFormsSubmittedNotification';
export const RECEIVE_PREVIOUS_FORMS_SUBMISSION_STATUS = 'ReceivePreviousFormsSubmissionStatus';

// Keyboard Key
export const enum KEYBOARD_KEYS {
  ARROW_RIGHT = 'ArrowRight',
  ARROW_LEFT = 'ArrowLeft',
  ENTER = 'Enter',
}
