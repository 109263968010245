var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};

// core/Data.js
function cloneObj(obj) {
  let clone = o => {
    var _a;
    for (let prop in o) {
      if (((_a = o[prop]) == null ? void 0 : _a.constructor) === Object) {
        o[prop] = clone(o[prop]);
      }
    }
    return {
      ...o
    };
  };
  return clone(obj);
}
var Data = class {
  constructor(schema) {
    if (schema.constructor === Object) {
      this.store = cloneObj(schema);
    } else {
      this._storeIsProxy = true;
      this.store = schema;
    }
    this.callbackMap = Object.create(null);
  }
  static warn(actionName, prop) {
    console.warn(`Symbiote Data: cannot ${actionName}. Prop name: ` + prop);
  }
  read(prop) {
    if (!this._storeIsProxy && !this.store.hasOwnProperty(prop)) {
      Data.warn("read", prop);
      return null;
    }
    return this.store[prop];
  }
  has(prop) {
    return this._storeIsProxy ? this.store[prop] !== void 0 : this.store.hasOwnProperty(prop);
  }
  add(prop, val, rewrite = false) {
    if (!rewrite && Object.keys(this.store).includes(prop)) {
      return;
    }
    this.store[prop] = val;
    this.notify(prop);
  }
  pub(prop, val) {
    if (!this._storeIsProxy && !this.store.hasOwnProperty(prop)) {
      Data.warn("publish", prop);
      return;
    }
    this.store[prop] = val;
    this.notify(prop);
  }
  multiPub(updObj) {
    for (let prop in updObj) {
      this.pub(prop, updObj[prop]);
    }
  }
  notify(prop) {
    if (this.callbackMap[prop]) {
      this.callbackMap[prop].forEach(callback => {
        callback(this.store[prop]);
      });
    }
  }
  sub(prop, callback, init = true) {
    if (!this._storeIsProxy && !this.store.hasOwnProperty(prop)) {
      Data.warn("subscribe", prop);
      return null;
    }
    if (!this.callbackMap[prop]) {
      this.callbackMap[prop] = /* @__PURE__ */new Set();
    }
    this.callbackMap[prop].add(callback);
    if (init) {
      callback(this.store[prop]);
    }
    return {
      remove: () => {
        this.callbackMap[prop].delete(callback);
        if (!this.callbackMap[prop].size) {
          delete this.callbackMap[prop];
        }
      },
      callback
    };
  }
  static registerCtx(schema, uid = Symbol()) {
    let data = Data.globalStore.get(uid);
    if (data) {
      console.warn('State: context UID "' + uid + '" already in use');
    } else {
      data = new Data(schema);
      Data.globalStore.set(uid, data);
    }
    return data;
  }
  static deleteCtx(uid) {
    Data.globalStore.delete(uid);
  }
  static getCtx(uid, notify = true) {
    return Data.globalStore.get(uid) || (notify && console.warn('State: wrong context UID - "' + uid + '"'), null);
  }
};
Data.globalStore = /* @__PURE__ */new Map();

// core/dictionary.js
var DICT = Object.freeze({
  BIND_ATTR: "set",
  ATTR_BIND_PRFX: "@",
  EXT_DATA_CTX_PRFX: "*",
  NAMED_DATA_CTX_SPLTR: "/",
  CTX_NAME_ATTR: "ctx-name",
  CTX_OWNER_ATTR: "ctx-owner",
  CSS_CTX_PROP: "--ctx-name",
  EL_REF_ATTR: "ref",
  AUTO_TAG_PRFX: "sym",
  REPEAT_ATTR: "repeat",
  REPEAT_ITEM_TAG_ATTR: "repeat-item-tag",
  SET_LATER_KEY: "__toSetLater__",
  USE_TPL: "use-template",
  ROOT_STYLE_ATTR_NAME: "sym-component"
});

// utils/UID.js
var CHARS = "1234567890QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm";
var CHLENGTH = CHARS.length - 1;
var UID = class {
  static generate(pattern = "XXXXXXXXX-XXX") {
    let uid = "";
    for (let i = 0; i < pattern.length; i++) {
      uid += pattern[i] === "-" ? pattern[i] : CHARS.charAt(Math.random() * CHLENGTH);
    }
    return uid;
  }
};

// utils/setNestedProp.js
function setNestedProp(parent, path, value) {
  let success = true;
  let lastStep;
  let propPath = path.split(".");
  propPath.forEach((step, idx) => {
    if (idx < propPath.length - 1) {
      parent = parent[step];
    } else {
      lastStep = step;
    }
  });
  if (parent) {
    parent[lastStep] = value;
  } else {
    success = false;
  }
  return success;
}

// utils/kebabToCamel.js
function kebabToCamel(string) {
  return string.split("-").map((p, i) => {
    return p && i ? p[0].toUpperCase() + p.slice(1) : p;
  }).join("").split("_").map((p, i) => {
    return p && i ? p.toUpperCase() : p;
  }).join("");
}

// core/repeatProcessor.js
function repeatProcessor(fr, fnCtx) {
  [...fr.querySelectorAll(`[${DICT.REPEAT_ATTR}]`)].forEach(el => {
    let itemTag = el.getAttribute(DICT.REPEAT_ITEM_TAG_ATTR);
    let itemClass;
    if (itemTag) {
      itemClass = window.customElements.get(itemTag);
    }
    if (!itemClass) {
      itemClass = class extends fnCtx.BaseComponent {
        constructor() {
          super();
          if (!itemTag) {
            this.style.display = "contents";
          }
        }
      };
      let itemTpl = el.innerHTML;
      itemClass.template = itemTpl;
      itemClass.reg(itemTag);
    }
    while (el.firstChild) {
      el.firstChild.remove();
    }
    let repeatDataKey = el.getAttribute(DICT.REPEAT_ATTR);
    fnCtx.sub(repeatDataKey, data => {
      if (!data) {
        while (el.firstChild) {
          el.firstChild.remove();
        }
        return;
      }
      let currentList = [...el.children];
      let fragment;
      let fillItems = items => {
        items.forEach((item, idx) => {
          if (currentList[idx]) {
            if (currentList[idx].set$) {
              setTimeout(() => {
                currentList[idx].set$(item);
              });
            } else {
              for (let k in item) {
                currentList[idx][k] = item[k];
              }
            }
          } else {
            if (!fragment) {
              fragment = new DocumentFragment();
            }
            let repeatItem = new itemClass();
            Object.assign(repeatItem.init$, item);
            fragment.appendChild(repeatItem);
          }
        });
        fragment && el.appendChild(fragment);
        let oversize = currentList.slice(items.length, currentList.length);
        for (let exItm of oversize) {
          exItm.remove();
        }
      };
      if (data.constructor === Array) {
        fillItems(data);
      } else if (data.constructor === Object) {
        let items = [];
        for (let itemKey in data) {
          let init = data[itemKey];
          Object.defineProperty(init, "_KEY_", {
            value: itemKey,
            enumerable: true
          });
          items.push(init);
        }
        fillItems(items);
      } else {
        console.warn("Symbiote repeat data type error:");
        console.log(data);
      }
    });
    el.removeAttribute(DICT.REPEAT_ATTR);
    el.removeAttribute(DICT.REPEAT_ITEM_TAG_ATTR);
  });
}

// core/tpl-processors.js
var DEFAULT_SLOT_KEY = "__default__";
function slotProcessor(fr, fnCtx) {
  if (fnCtx.shadowRoot) {
    return;
  }
  let slots = [...fr.querySelectorAll("slot")];
  if (!slots.length) {
    return;
  }
  let slotMap = {};
  slots.forEach(slot => {
    let slotName = slot.getAttribute("name") || DEFAULT_SLOT_KEY;
    slotMap[slotName] = {
      slot,
      fr: document.createDocumentFragment()
    };
  });
  fnCtx.initChildren.forEach(child => {
    var _a;
    let slotName = DEFAULT_SLOT_KEY;
    if (child instanceof Element && child.hasAttribute("slot")) {
      slotName = child.getAttribute("slot");
      child.removeAttribute("slot");
    }
    (_a = slotMap[slotName]) == null ? void 0 : _a.fr.appendChild(child);
  });
  Object.values(slotMap).forEach(mapObj => {
    if (mapObj.fr.childNodes.length) {
      mapObj.slot.parentNode.replaceChild(mapObj.fr, mapObj.slot);
    } else if (mapObj.slot.childNodes.length) {
      let slotFr = document.createDocumentFragment();
      slotFr.append(...mapObj.slot.childNodes);
      mapObj.slot.parentNode.replaceChild(slotFr, mapObj.slot);
    } else {
      mapObj.slot.remove();
    }
  });
}
function refProcessor(fr, fnCtx) {
  [...fr.querySelectorAll(`[${DICT.EL_REF_ATTR}]`)].forEach(el => {
    let refName = el.getAttribute(DICT.EL_REF_ATTR);
    fnCtx.ref[refName] = el;
    el.removeAttribute(DICT.EL_REF_ATTR);
  });
}
function domSetProcessor(fr, fnCtx) {
  [...fr.querySelectorAll(`[${DICT.BIND_ATTR}]`)].forEach(el => {
    let subStr = el.getAttribute(DICT.BIND_ATTR);
    let keyValArr = subStr.split(";");
    [...el.attributes].forEach(attr => {
      if (attr.name.startsWith("-") && attr.value) {
        let key = kebabToCamel(attr.name.replace("-", ""));
        keyValArr.push(key + ":" + attr.value);
        el.removeAttribute(attr.name);
      }
    });
    keyValArr.forEach(keyValStr => {
      if (!keyValStr) {
        return;
      }
      let kv = keyValStr.split(":").map(str => str.trim());
      let prop = kv[0];
      let isAttr;
      if (prop.indexOf(DICT.ATTR_BIND_PRFX) === 0) {
        isAttr = true;
        prop = prop.replace(DICT.ATTR_BIND_PRFX, "");
      }
      let valKeysArr = kv[1].split(",").map(valKey => {
        return valKey.trim();
      });
      for (let valKey of valKeysArr) {
        let castType;
        if (valKey.startsWith("!!")) {
          castType = "double";
          valKey = valKey.replace("!!", "");
        } else if (valKey.startsWith("!")) {
          castType = "single";
          valKey = valKey.replace("!", "");
        }
        fnCtx.sub(valKey, val => {
          if (castType === "double") {
            val = !!val;
          } else if (castType === "single") {
            val = !val;
          }
          if (isAttr) {
            if ((val == null ? void 0 : val.constructor) === Boolean) {
              val ? el.setAttribute(prop, "") : el.removeAttribute(prop);
            } else {
              el.setAttribute(prop, val);
            }
          } else {
            if (!setNestedProp(el, prop, val)) {
              if (!el[DICT.SET_LATER_KEY]) {
                el[DICT.SET_LATER_KEY] = Object.create(null);
              }
              el[DICT.SET_LATER_KEY][prop] = val;
            }
          }
        });
      }
    });
    el.removeAttribute(DICT.BIND_ATTR);
  });
}
var OPEN_TOKEN = "{{";
var CLOSE_TOKEN = "}}";
var SKIP_ATTR = "skip-text";
function getTextNodesWithTokens(el) {
  let node;
  let result = [];
  let walk = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, {
    acceptNode: txt => {
      var _a;
      return !((_a = txt.parentElement) == null ? void 0 : _a.hasAttribute(SKIP_ATTR)) && txt.textContent.includes(OPEN_TOKEN) && txt.textContent.includes(CLOSE_TOKEN) && 1;
    }
  });
  while (node = walk.nextNode()) {
    result.push(node);
  }
  return result;
}
var txtNodesProcessor = function (fr, fnCtx) {
  let txtNodes = getTextNodesWithTokens(fr);
  txtNodes.forEach(txtNode => {
    let tokenNodes = [];
    let offset;
    while (txtNode.textContent.includes(CLOSE_TOKEN)) {
      if (txtNode.textContent.startsWith(OPEN_TOKEN)) {
        offset = txtNode.textContent.indexOf(CLOSE_TOKEN) + CLOSE_TOKEN.length;
        txtNode.splitText(offset);
        tokenNodes.push(txtNode);
      } else {
        offset = txtNode.textContent.indexOf(OPEN_TOKEN);
        txtNode.splitText(offset);
      }
      txtNode = txtNode.nextSibling;
    }
    tokenNodes.forEach(tNode => {
      let prop = tNode.textContent.replace(OPEN_TOKEN, "").replace(CLOSE_TOKEN, "");
      tNode.textContent = "";
      fnCtx.sub(prop, val => {
        tNode.textContent = val;
      });
    });
  });
};
var tpl_processors_default = [repeatProcessor, slotProcessor, refProcessor, domSetProcessor, txtNodesProcessor];

// utils/parseCssPropertyValue.js
var SINGLE_QUOTE = "'";
var DOUBLE_QUOTE = '"';
var ESCAPED_PATTERN = /\\([0-9a-fA-F]{1,6} ?)/g;
function hasLeadingTrailingQuotes(str) {
  return (str[0] === DOUBLE_QUOTE || str[0] === SINGLE_QUOTE) && (str[str.length - 1] === DOUBLE_QUOTE || str[str.length - 1] === SINGLE_QUOTE);
}
function trimQuotes(str) {
  if (str[0] === DOUBLE_QUOTE || str[0] === SINGLE_QUOTE) {
    str = str.slice(1);
  }
  if (str[str.length - 1] === DOUBLE_QUOTE || str[str.length - 1] === SINGLE_QUOTE) {
    str = str.slice(0, -1);
  }
  return str;
}
function escapeQuotes(str) {
  let result = "";
  let prev = "";
  for (var i = 0; i < str.length; i++) {
    const next = str[i + 1];
    if (str[i] === "\\" && next === '"') {
      result += '\\"';
      i++;
    } else if (str[i] === '"' && prev !== "\\") {
      result += '\\"';
    } else {
      result += str[i];
    }
    prev = str[i];
  }
  return result;
}
function parseCssPropertyValue(input) {
  let output = input;
  if (hasLeadingTrailingQuotes(input)) {
    output = trimQuotes(output);
    output = output.replace(ESCAPED_PATTERN, (match, p1) => {
      return String.fromCodePoint(parseInt(p1.trim(), 16));
    });
    output = output.replaceAll("\\\n", "\\n");
    output = escapeQuotes(output);
    output = DOUBLE_QUOTE + output + DOUBLE_QUOTE;
  }
  try {
    return JSON.parse(output);
  } catch (err) {
    throw new Error(`Failed to parse CSS property value: ${output}. Original input: ${input}`);
  }
}

// core/BaseComponent.js
var autoTagsCount = 0;
var styleMutationObserver = null;
var styleMutationObserverCbList = null;
var _BaseComponent = class extends HTMLElement {
  constructor() {
    super();
    __publicField(this, "updateCssData", () => {
      var _a;
      this.dropCssDataCache();
      (_a = this.__boundCssProps) == null ? void 0 : _a.forEach(ctxProp => {
        let val = this.getCssData(this.__extractCssName(ctxProp), true);
        val !== null && this.$[ctxProp] !== val && (this.$[ctxProp] = val);
      });
    });
    this.init$ = Object.create(null);
    this.cssInit$ = Object.create(null);
    this.tplProcessors = /* @__PURE__ */new Set();
    this.ref = Object.create(null);
    this.allSubs = /* @__PURE__ */new Set();
    this.pauseRender = false;
    this.renderShadow = false;
    this.readyToDestroy = true;
    this.processInnerHtml = false;
    this.allowCustomTemplate = false;
    this.ctxOwner = false;
  }
  get BaseComponent() {
    return _BaseComponent;
  }
  initCallback() {}
  __initCallback() {
    var _a;
    if (this.__initialized) {
      return;
    }
    this.__initialized = true;
    (_a = this.initCallback) == null ? void 0 : _a.call(this);
  }
  render(template, shadow = this.renderShadow) {
    let fr;
    if ((shadow || this.constructor["__shadowStylesUrl"]) && !this.shadowRoot) {
      this.attachShadow({
        mode: "open"
      });
    }
    if (this.allowCustomTemplate) {
      let customTplSelector = this.getAttribute(DICT.USE_TPL);
      if (customTplSelector) {
        let root = this.getRootNode();
        let customTpl = (root == null ? void 0 : root.querySelector(customTplSelector)) || document.querySelector(customTplSelector);
        if (customTpl) {
          template = customTpl.content.cloneNode(true);
        } else {
          console.warn(`Symbiote template "${customTplSelector}" is not found...`);
        }
      }
    }
    if (this.processInnerHtml) {
      for (let fn of this.tplProcessors) {
        fn(this, this);
      }
    }
    if (template || this.constructor["template"]) {
      if (this.constructor["template"] && !this.constructor["__tpl"]) {
        this.constructor["__tpl"] = document.createElement("template");
        this.constructor["__tpl"].innerHTML = this.constructor["template"];
      }
      if ((template == null ? void 0 : template.constructor) === DocumentFragment) {
        fr = template;
      } else if ((template == null ? void 0 : template.constructor) === String) {
        let tpl = document.createElement("template");
        tpl.innerHTML = template;
        fr = tpl.content.cloneNode(true);
      } else if (this.constructor["__tpl"]) {
        fr = this.constructor["__tpl"].content.cloneNode(true);
      }
      for (let fn of this.tplProcessors) {
        fn(fr, this);
      }
    }
    let addFr = () => {
      fr && (shadow && this.shadowRoot.appendChild(fr) || this.appendChild(fr));
      this.__initCallback();
    };
    if (this.constructor["__shadowStylesUrl"]) {
      shadow = true;
      let styleLink = document.createElement("link");
      styleLink.rel = "stylesheet";
      styleLink.href = this.constructor["__shadowStylesUrl"];
      styleLink.onload = addFr;
      this.shadowRoot.prepend(styleLink);
    } else {
      addFr();
    }
  }
  addTemplateProcessor(processorFn) {
    this.tplProcessors.add(processorFn);
  }
  get autoCtxName() {
    if (!this.__autoCtxName) {
      this.__autoCtxName = UID.generate();
      this.style.setProperty(DICT.CSS_CTX_PROP, `'${this.__autoCtxName}'`);
    }
    return this.__autoCtxName;
  }
  get cssCtxName() {
    return this.getCssData(DICT.CSS_CTX_PROP, true);
  }
  get ctxName() {
    var _a;
    let ctxName = ((_a = this.getAttribute(DICT.CTX_NAME_ATTR)) == null ? void 0 : _a.trim()) || this.cssCtxName || this.__cachedCtxName || this.autoCtxName;
    this.__cachedCtxName = ctxName;
    return ctxName;
  }
  get localCtx() {
    if (!this.__localCtx) {
      this.__localCtx = Data.registerCtx({}, this);
    }
    return this.__localCtx;
  }
  get nodeCtx() {
    return Data.getCtx(this.ctxName, false) || Data.registerCtx({}, this.ctxName);
  }
  static __parseProp(prop, fnCtx) {
    let ctx;
    let name;
    if (prop.startsWith(DICT.EXT_DATA_CTX_PRFX)) {
      ctx = fnCtx.nodeCtx;
      name = prop.replace(DICT.EXT_DATA_CTX_PRFX, "");
    } else if (prop.includes(DICT.NAMED_DATA_CTX_SPLTR)) {
      let pArr = prop.split(DICT.NAMED_DATA_CTX_SPLTR);
      ctx = Data.getCtx(pArr[0]);
      name = pArr[1];
    } else {
      ctx = fnCtx.localCtx;
      name = prop;
    }
    return {
      ctx,
      name
    };
  }
  sub(prop, handler, init = true) {
    let subCb = val => {
      if (!this.isConnected) {
        return;
      }
      handler(val);
    };
    let parsed = _BaseComponent.__parseProp(prop, this);
    if (!parsed.ctx.has(parsed.name)) {
      window.setTimeout(() => {
        this.allSubs.add(parsed.ctx.sub(parsed.name, subCb, init));
      });
    } else {
      this.allSubs.add(parsed.ctx.sub(parsed.name, subCb, init));
    }
  }
  notify(prop) {
    let parsed = _BaseComponent.__parseProp(prop, this);
    parsed.ctx.notify(parsed.name);
  }
  has(prop) {
    let parsed = _BaseComponent.__parseProp(prop, this);
    return parsed.ctx.has(parsed.name);
  }
  add(prop, val, rewrite = false) {
    let parsed = _BaseComponent.__parseProp(prop, this);
    parsed.ctx.add(parsed.name, val, rewrite);
  }
  add$(obj, rewrite = false) {
    for (let prop in obj) {
      this.add(prop, obj[prop], rewrite);
    }
  }
  get $() {
    if (!this.__stateProxy) {
      let o = Object.create(null);
      this.__stateProxy = new Proxy(o, {
        set: (obj, prop, val) => {
          let parsed = _BaseComponent.__parseProp(prop, this);
          parsed.ctx.pub(parsed.name, val);
          return true;
        },
        get: (obj, prop) => {
          let parsed = _BaseComponent.__parseProp(prop, this);
          return parsed.ctx.read(parsed.name);
        }
      });
    }
    return this.__stateProxy;
  }
  set$(kvObj, forcePrimitives = false) {
    for (let key in kvObj) {
      let val = kvObj[key];
      let primArr = [String, Number, Boolean];
      if (forcePrimitives || !primArr.includes(val == null ? void 0 : val.constructor)) {
        this.$[key] = val;
      } else {
        this.$[key] !== val && (this.$[key] = val);
      }
    }
  }
  get __ctxOwner() {
    return this.ctxOwner || this.hasAttribute(DICT.CTX_OWNER_ATTR) && this.getAttribute(DICT.CTX_OWNER_ATTR) !== "false";
  }
  __initDataCtx() {
    let attrDesc = this.constructor["__attrDesc"];
    if (attrDesc) {
      for (let prop of Object.values(attrDesc)) {
        if (!Object.keys(this.init$).includes(prop)) {
          this.init$[prop] = "";
        }
      }
    }
    for (let prop in this.init$) {
      if (prop.startsWith(DICT.EXT_DATA_CTX_PRFX)) {
        this.nodeCtx.add(prop.replace(DICT.EXT_DATA_CTX_PRFX, ""), this.init$[prop], this.__ctxOwner);
      } else if (prop.includes(DICT.NAMED_DATA_CTX_SPLTR)) {
        let propArr = prop.split(DICT.NAMED_DATA_CTX_SPLTR);
        let ctxName = propArr[0].trim();
        let propName = propArr[1].trim();
        if (ctxName && propName) {
          let namedCtx = Data.getCtx(ctxName, false);
          if (!namedCtx) {
            namedCtx = Data.registerCtx({}, ctxName);
          }
          namedCtx.add(propName, this.init$[prop]);
        }
      } else {
        this.localCtx.add(prop, this.init$[prop]);
      }
    }
    for (let cssProp in this.cssInit$) {
      this.bindCssData(cssProp, this.cssInit$[cssProp]);
    }
    this.__dataCtxInitialized = true;
  }
  connectedCallback() {
    var _a;
    if (!this.isConnected) {
      return;
    }
    if (this.__disconnectTimeout) {
      window.clearTimeout(this.__disconnectTimeout);
    }
    if (!this.connectedOnce) {
      let ctxNameAttrVal = (_a = this.getAttribute(DICT.CTX_NAME_ATTR)) == null ? void 0 : _a.trim();
      if (ctxNameAttrVal) {
        this.style.setProperty(DICT.CSS_CTX_PROP, `'${ctxNameAttrVal}'`);
      }
      this.__initDataCtx();
      if (this[DICT.SET_LATER_KEY]) {
        for (let prop in this[DICT.SET_LATER_KEY]) {
          setNestedProp(this, prop, this[DICT.SET_LATER_KEY][prop]);
        }
        delete this[DICT.SET_LATER_KEY];
      }
      this.initChildren = [...this.childNodes];
      for (let proc of tpl_processors_default) {
        this.addTemplateProcessor(proc);
      }
      if (this.pauseRender) {
        this.__initCallback();
      } else {
        if (this.constructor["__rootStylesLink"]) {
          let root = this.getRootNode();
          if (!root) {
            return;
          }
          let hasLink = root == null ? void 0 : root.querySelector(`link[${DICT.ROOT_STYLE_ATTR_NAME}="${this.constructor.is}"]`);
          if (hasLink) {
            this.render();
            return;
          }
          let rootLink = this.constructor["__rootStylesLink"].cloneNode(true);
          rootLink.setAttribute(DICT.ROOT_STYLE_ATTR_NAME, this.constructor["is"]);
          rootLink.onload = () => {
            this.render();
          };
          root.nodeType === Node.DOCUMENT_NODE ? root.head.appendChild(rootLink) : root.prepend(rootLink);
        } else {
          this.render();
        }
      }
    }
    this.connectedOnce = true;
  }
  destroyCallback() {}
  disconnectedCallback() {
    if (!this.connectedOnce) {
      return;
    }
    this.dropCssDataCache();
    if (!this.readyToDestroy) {
      return;
    }
    if (this.__disconnectTimeout) {
      window.clearTimeout(this.__disconnectTimeout);
    }
    this.__disconnectTimeout = window.setTimeout(() => {
      this.destroyCallback();
      for (let sub of this.allSubs) {
        sub.remove();
        this.allSubs.delete(sub);
      }
      for (let proc of this.tplProcessors) {
        this.tplProcessors.delete(proc);
      }
      styleMutationObserverCbList == null ? void 0 : styleMutationObserverCbList.delete(this.updateCssData);
      if (!(styleMutationObserverCbList == null ? void 0 : styleMutationObserverCbList.size)) {
        styleMutationObserver == null ? void 0 : styleMutationObserver.disconnect();
        styleMutationObserver = null;
        styleMutationObserverCbList = null;
      }
    }, 100);
  }
  static reg(tagName, isAlias = false) {
    if (!tagName) {
      autoTagsCount++;
      tagName = `${DICT.AUTO_TAG_PRFX}-${autoTagsCount}`;
    }
    this.__tag = tagName;
    let registeredClass = window.customElements.get(tagName);
    if (registeredClass) {
      if (!isAlias && registeredClass !== this) {
        console.warn([`Element with tag name "${tagName}" already registered.`, `You're trying to override it with another class "${this.name}".`, `This is most likely a mistake.`, `New element will not be registered.`].join("\n"));
      }
      return;
    }
    window.customElements.define(tagName, isAlias ? class extends this {} : this);
  }
  static get is() {
    if (!this.__tag) {
      this.reg();
    }
    return this.__tag;
  }
  static bindAttributes(desc) {
    this.observedAttributes = Object.keys(desc);
    this.__attrDesc = desc;
  }
  attributeChangedCallback(name, oldVal, newVal) {
    var _a;
    if (oldVal === newVal) {
      return;
    }
    let $prop = (_a = this.constructor["__attrDesc"]) == null ? void 0 : _a[name];
    if ($prop) {
      if (this.__dataCtxInitialized) {
        this.$[$prop] = newVal;
      } else {
        this.init$[$prop] = newVal;
      }
    } else {
      this[name] = newVal;
    }
  }
  getCssData(propName, silentCheck = false) {
    if (!this.__cssDataCache) {
      this.__cssDataCache = Object.create(null);
    }
    if (!Object.keys(this.__cssDataCache).includes(propName)) {
      if (!this.__computedStyle) {
        this.__computedStyle = window.getComputedStyle(this);
      }
      let val = this.__computedStyle.getPropertyValue(propName).trim();
      try {
        this.__cssDataCache[propName] = parseCssPropertyValue(val);
      } catch (e) {
        !silentCheck && console.warn(`CSS Data error: ${propName}`);
        this.__cssDataCache[propName] = null;
      }
    }
    return this.__cssDataCache[propName];
  }
  __extractCssName(ctxPropName) {
    return ctxPropName.split("--").map((part, idx) => {
      return idx === 0 ? "" : part;
    }).join("--");
  }
  __initStyleAttrObserver() {
    if (!styleMutationObserverCbList) {
      styleMutationObserverCbList = /* @__PURE__ */new Set();
    }
    styleMutationObserverCbList.add(this.updateCssData);
    if (!styleMutationObserver) {
      styleMutationObserver = new MutationObserver(records => {
        records[0].type === "attributes" && styleMutationObserverCbList.forEach(cb => {
          cb();
        });
      });
      styleMutationObserver.observe(document, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ["style"]
      });
    }
  }
  bindCssData(propName, initValue = "") {
    if (!this.__boundCssProps) {
      this.__boundCssProps = /* @__PURE__ */new Set();
    }
    this.__boundCssProps.add(propName);
    let val = this.getCssData(this.__extractCssName(propName), true);
    val === null && (val = initValue);
    this.add(propName, val);
    this.__initStyleAttrObserver();
  }
  dropCssDataCache() {
    this.__cssDataCache = null;
    this.__computedStyle = null;
  }
  defineAccessor(propName, handler, isAsync) {
    let localPropName = "__" + propName;
    this[localPropName] = this[propName];
    Object.defineProperty(this, propName, {
      set: val => {
        this[localPropName] = val;
        if (isAsync) {
          window.setTimeout(() => {
            handler == null ? void 0 : handler(val);
          });
        } else {
          handler == null ? void 0 : handler(val);
        }
      },
      get: () => {
        return this[localPropName];
      }
    });
    this[propName] = this[localPropName];
  }
  static set shadowStyles(cssTxt) {
    let styleBlob = new Blob([cssTxt], {
      type: "text/css"
    });
    this.__shadowStylesUrl = URL.createObjectURL(styleBlob);
  }
  static set rootStyles(cssTxt) {
    if (!this.__rootStylesLink) {
      let styleBlob = new Blob([cssTxt], {
        type: "text/css"
      });
      let url = URL.createObjectURL(styleBlob);
      let link = document.createElement("link");
      link.href = url;
      link.rel = "stylesheet";
      this.__rootStylesLink = link;
    }
  }
};
var BaseComponent = _BaseComponent;
__publicField(BaseComponent, "template");

// core/AppRouter.js
var AppRouter = class {
  static _print(msg) {
    console.warn(msg);
  }
  static setDefaultTitle(title) {
    this.defaultTitle = title;
  }
  static setRoutingMap(map) {
    Object.assign(this.appMap, map);
    for (let route in this.appMap) {
      if (!this.defaultRoute && this.appMap[route].default === true) {
        this.defaultRoute = route;
      } else if (!this.errorRoute && this.appMap[route].error === true) {
        this.errorRoute = route;
      }
    }
  }
  static set routingEventName(name) {
    this.__routingEventName = name;
  }
  static get routingEventName() {
    return this.__routingEventName || "sym-on-route";
  }
  static readAddressBar() {
    let result = {
      route: null,
      options: {}
    };
    let paramsArr = window.location.search.split(this.separator);
    paramsArr.forEach(part => {
      if (part.includes("?")) {
        result.route = part.replace("?", "");
      } else if (part.includes("=")) {
        let pair = part.split("=");
        result.options[pair[0]] = decodeURI(pair[1]);
      } else {
        result.options[part] = true;
      }
    });
    return result;
  }
  static notify() {
    let routeBase = this.readAddressBar();
    let routeScheme = this.appMap[routeBase.route];
    if (routeScheme && routeScheme.title) {
      document.title = routeScheme.title;
    }
    if (routeBase.route === null && this.defaultRoute) {
      this.applyRoute(this.defaultRoute);
      return;
    } else if (!routeScheme && this.errorRoute) {
      this.applyRoute(this.errorRoute);
      return;
    } else if (!routeScheme && this.defaultRoute) {
      this.applyRoute(this.defaultRoute);
      return;
    } else if (!routeScheme) {
      this._print(`Route "${routeBase.route}" not found...`);
      return;
    }
    let event = new CustomEvent(AppRouter.routingEventName, {
      detail: {
        route: routeBase.route,
        options: Object.assign(routeScheme || {}, routeBase.options)
      }
    });
    window.dispatchEvent(event);
  }
  static reflect(route, options = {}) {
    let routeScheme = this.appMap[route];
    if (!routeScheme) {
      this._print("Wrong route: " + route);
      return;
    }
    let routeStr = "?" + route;
    for (let prop in options) {
      if (options[prop] === true) {
        routeStr += this.separator + prop;
      } else {
        routeStr += this.separator + prop + `=${options[prop]}`;
      }
    }
    let title = routeScheme.title || this.defaultTitle || "";
    window.history.pushState(null, title, routeStr);
    document.title = title;
  }
  static applyRoute(route, options = {}) {
    this.reflect(route, options);
    this.notify();
  }
  static setSeparator(char) {
    this._separator = char;
  }
  static get separator() {
    return this._separator || "&";
  }
  static createRouterData(ctxName, routingMap) {
    this.setRoutingMap(routingMap);
    let routeData = Data.registerCtx({
      route: null,
      options: null,
      title: null
    }, ctxName);
    window.addEventListener(this.routingEventName, e => {
      var _a;
      routeData.multiPub({
        route: e.detail.route,
        options: e.detail.options,
        title: ((_a = e.detail.options) == null ? void 0 : _a.title) || this.defaultTitle || ""
      });
    });
    AppRouter.notify();
    this.initPopstateListener();
    return routeData;
  }
  static initPopstateListener() {
    if (this.__onPopstate) {
      return;
    }
    this.__onPopstate = () => {
      this.notify();
    };
    window.addEventListener("popstate", this.__onPopstate);
  }
  static removePopstateListener() {
    window.removeEventListener("popstate", this.__onPopstate);
    this.__onPopstate = null;
  }
};
AppRouter.appMap = Object.create(null);

// utils/dom-helpers.js
function applyStyles(el, styleMap) {
  for (let prop in styleMap) {
    if (prop.includes("-")) {
      el.style.setProperty(prop, styleMap[prop]);
    } else {
      el.style[prop] = styleMap[prop];
    }
  }
}
function applyAttributes(el, attrMap) {
  for (let attrName in attrMap) {
    if (attrMap[attrName].constructor === Boolean) {
      if (attrMap[attrName]) {
        el.setAttribute(attrName, "");
      } else {
        el.removeAttribute(attrName);
      }
    } else {
      el.setAttribute(attrName, attrMap[attrName]);
    }
  }
}
function create(desc = {
  tag: "div"
}) {
  let el = document.createElement(desc.tag);
  if (desc.attributes) {
    applyAttributes(el, desc.attributes);
  }
  if (desc.styles) {
    applyStyles(el, desc.styles);
  }
  if (desc.properties) {
    for (let prop in desc.properties) {
      el[prop] = desc.properties[prop];
    }
  }
  if (desc.processors) {
    desc.processors.forEach(fn => {
      fn(el);
    });
  }
  if (desc.children) {
    desc.children.forEach(desc2 => {
      let child = create(desc2);
      el.appendChild(child);
    });
  }
  return el;
}

// utils/IDB.js
var READY_EVENT_NAME = "idb-store-ready";
var DEFAULT_DB_NAME = `symbiote-db`;
var UPD_EVENT_PREFIX = `symbiote-idb-update_`;
var DbInstance = class {
  _notifyWhenReady(event = null) {
    window.dispatchEvent(new CustomEvent(READY_EVENT_NAME, {
      detail: {
        dbName: this.name,
        storeName: this.storeName,
        event
      }
    }));
  }
  get _updEventName() {
    return UPD_EVENT_PREFIX + this.name;
  }
  _getUpdateEvent(key) {
    return new CustomEvent(this._updEventName, {
      detail: {
        key: this.name,
        newValue: key
      }
    });
  }
  _notifySubscribers(key) {
    window.localStorage.removeItem(this.name);
    window.localStorage.setItem(this.name, key);
    window.dispatchEvent(this._getUpdateEvent(key));
  }
  constructor(dbName, storeName) {
    this.name = dbName;
    this.storeName = storeName;
    this.version = 1;
    this.request = window.indexedDB.open(this.name, this.version);
    this.request.onupgradeneeded = e => {
      this.db = e.target["result"];
      this.objStore = this.db.createObjectStore(storeName, {
        keyPath: "_key"
      });
      this.objStore.transaction.oncomplete = ev => {
        this._notifyWhenReady(ev);
      };
    };
    this.request.onsuccess = e => {
      this.db = e.target.result;
      this._notifyWhenReady(e);
    };
    this.request.onerror = e => {
      console.error(e);
    };
    this._subscriptionsMap = {};
    this._updateHandler = e => {
      if (e.key === this.name && this._subscriptionsMap[e.newValue]) {
        let set = this._subscriptionsMap[e.newValue];
        set.forEach(async callback => {
          callback(await this.read(e.newValue));
        });
      }
    };
    this._localUpdateHandler = e => {
      this._updateHandler(e.detail);
    };
    window.addEventListener("storage", this._updateHandler);
    window.addEventListener(this._updEventName, this._localUpdateHandler);
  }
  read(key) {
    let tx = this.db.transaction(this.storeName, "readwrite");
    let request = tx.objectStore(this.storeName).get(key);
    return new Promise((resolve, reject) => {
      request.onsuccess = e => {
        var _a;
        if ((_a = e.target.result) == null ? void 0 : _a._value) {
          resolve(e.target.result._value);
        } else {
          resolve(null);
          console.warn(`IDB: cannot read "${key}"`);
        }
      };
      request.onerror = e => {
        reject(e);
      };
    });
  }
  write(key, value, silent = false) {
    let data = {
      _key: key,
      _value: value
    };
    let tx = this.db.transaction(this.storeName, "readwrite");
    let request = tx.objectStore(this.storeName).put(data);
    return new Promise((resolve, reject) => {
      request.onsuccess = e => {
        if (!silent) {
          this._notifySubscribers(key);
        }
        resolve(e.target.result);
      };
      request.onerror = e => {
        reject(e);
      };
    });
  }
  delete(key, silent = false) {
    let tx = this.db.transaction(this.storeName, "readwrite");
    let request = tx.objectStore(this.storeName).delete(key);
    return new Promise((resolve, reject) => {
      request.onsuccess = e => {
        if (!silent) {
          this._notifySubscribers(key);
        }
        resolve(e);
      };
      request.onerror = e => {
        reject(e);
      };
    });
  }
  getAll() {
    let tx = this.db.transaction(this.storeName, "readwrite");
    let request = tx.objectStore(this.storeName).getAll();
    return new Promise((resolve, reject) => {
      request.onsuccess = e => {
        let all = e.target.result;
        resolve(all.map(obj => {
          return obj._value;
        }));
      };
      request.onerror = e => {
        reject(e);
      };
    });
  }
  subscribe(key, callback) {
    if (!this._subscriptionsMap[key]) {
      this._subscriptionsMap[key] = /* @__PURE__ */new Set();
    }
    let set = this._subscriptionsMap[key];
    set.add(callback);
    return {
      remove: () => {
        set.delete(callback);
        if (!set.size) {
          delete this._subscriptionsMap[key];
        }
      }
    };
  }
  stop() {
    window.removeEventListener("storage", this._updateHandler);
    this._subscriptionsMap = null;
    IDB.clear(this.name);
  }
};
var IDB = class {
  static get readyEventName() {
    return READY_EVENT_NAME;
  }
  static open(dbName = DEFAULT_DB_NAME, storeName = "store") {
    let key = dbName + "/" + storeName;
    if (!this._reg[key]) {
      this._reg[key] = new DbInstance(dbName, storeName);
    }
    return this._reg[key];
  }
  static clear(dbName) {
    window.indexedDB.deleteDatabase(dbName);
    for (let key in this._reg) {
      if (key.split("/")[0] === dbName) {
        delete this._reg[key];
      }
    }
  }
};
__publicField(IDB, "_reg", Object.create(null));
export { AppRouter, BaseComponent, Data, IDB, UID, applyAttributes, applyStyles, create, kebabToCamel, setNestedProp };