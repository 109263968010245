import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, Signal, OnInit, HostListener } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';

import { PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { filter, Subject, switchMap, takeUntil, tap, timer } from 'rxjs';

import { KEYBOARD_KEYS } from '@abbadox-monorepo/core-constants';
import { LocalStorageService } from '@abbadox-monorepo/core-utils';
import { AppointmentsStore } from '@abbadox-monorepo/kiosk-appointments-data-access';
import { AuthStore } from '@abbadox-monorepo/kiosk-auth-data-access';
import {
  LOCAL_STORAGE_KIOSK_REFRESH_TOKEN_EXPIRATION_KEY,
  STARTING_VALUE_TO_EMIT,
  INTERVAL_TO_EMIT_IN_MILLISECONDS,
} from '@abbadox-monorepo/kiosk-core-constants';
import {
  PromptUpdateService,
  LogUpdateService,
  HandleUnrecoverableStateService,
} from '@abbadox-monorepo/kiosk-core-pwa-services';
import {
  RealtimeFormsActions,
  RealtimeFormsStore,
  selectEformsCompletedStatus,
} from '@abbadox-monorepo/kiosk-eforms-data-access';
import { defaultPatient, PatientDetailsStore, PatientsSearchStore } from '@abbadox-monorepo/kiosk-patient-data-access';
import { KioskHeader, KioskFooter } from '@abbadox-monorepo/kiosk-ui';
import { UploadWizardStore, FilesStore } from '@abbadox-monorepo/kiosk-upload-data-access';
import {
  KioskConfigurationsStore,
  STEP_ROUTES,
  StepWidget,
  WIDGET_NAMES,
} from '@abbadox-monorepo/kiosk-workflows-data-access';
import { IdsSpinner, IdsSpinnerOverlay } from '@abbadox-monorepo/shared-ui';

type WorkflowStepHeaderViewModel = {
  logo: string;
  stepTitle: string;
  stepName: string;
  percentage: number;
};

type WorkflowFooterViewModel = {
  currentStep: string;
  prevStep: string;
  nextStep: string;
  nextButtonVisible: boolean;
};

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [PushPipe, NgClass, RouterModule, IdsSpinnerOverlay, IdsSpinner, KioskHeader, KioskFooter],
  template: `
    @if (timer$ | ngrxPush) {}
    <kiosk-header
      [logo]="headerViewModel().logo"
      [stepTitle]="headerViewModel().stepTitle"
      [stepName]="headerViewModel().stepName"
      [percentage]="headerViewModel().percentage"
    ></kiosk-header>

    @if (loading()) {
      <ids-spinner-overlay overlay="true"><ids-spinner></ids-spinner></ids-spinner-overlay>
    }

    <main
      class="relative top-[84px] mx-auto pb-28 pt-4"
      [ngClass]="{ 'max-w-full': realtimeFormsStep(), 'max-w-[50.125rem] px-4': !realtimeFormsStep() }"
    >
      <router-outlet></router-outlet>
    </main>

    <kiosk-footer
      [currentStep]="footerViewModel().currentStep"
      [prevStep]="footerViewModel().prevStep"
      [nextStep]="footerViewModel().nextStep"
      [nextButtonVisible]="footerViewModel().nextButtonVisible"
      (prevStepClicked)="prevStep()"
      (restartWorkflowClicked)="initWorkflow()"
      (nextStepClicked)="nextStep()"
      (logoutButtonClicked)="handleLogoutClick()"
    ></kiosk-footer>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  private readonly promptUpdateService = inject(PromptUpdateService);
  private readonly logUpdateService = inject(LogUpdateService);
  private readonly handleUnrecoverableStateService = inject(HandleUnrecoverableStateService);

  private readonly store = inject(Store);
  readonly eformsCompletedStatus = toSignal(this.store.select(selectEformsCompletedStatus));

  private readonly authStore = inject(AuthStore);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly kioskConfigStore = inject(KioskConfigurationsStore);
  private readonly patientsSearchStore = inject(PatientsSearchStore);
  private readonly patientDetailsStore = inject(PatientDetailsStore);
  private readonly appointmentsStore = inject(AppointmentsStore);
  private readonly uploadWizardStore = inject(UploadWizardStore);
  private readonly filesStore = inject(FilesStore);
  private readonly realtimeFormsStore = inject(RealtimeFormsStore);

  readonly loading: Signal<boolean> = computed(
    () =>
      this.authStore.loading() ||
      this.kioskConfigStore.loading() ||
      this.patientsSearchStore.loading() ||
      this.appointmentsStore.loading() ||
      this.filesStore.loading() ||
      this.realtimeFormsStore.loading(),
  );

  readonly headerViewModel: Signal<WorkflowStepHeaderViewModel> = computed(() => {
    const logo = this.authStore.logo() ?? '';
    const { stepTitle, stepName, stepsProgress } = this.kioskConfigStore;

    return {
      logo,
      stepTitle: stepTitle(),
      stepName: stepName(),
      percentage: stepsProgress(),
    };
  });

  readonly footerViewModel: Signal<WorkflowFooterViewModel> = computed(() => {
    const { currentStepRoute, nextStepRoute, prevStepRoute } = this.kioskConfigStore;
    const currentStep = currentStepRoute() ?? '';
    const nextButtonVisible = this.isNextButtonVisible();

    if (currentStep && /confirmation/.test(currentStep)) {
      return { currentStep, nextStep: '', prevStep: '', nextButtonVisible };
    }

    return { currentStep, nextStep: nextStepRoute(), prevStep: prevStepRoute(), nextButtonVisible };
  });
  readonly realtimeFormsStep = this.kioskConfigStore.realtimeFormsStep;

  @HostListener('window:keyup', ['$event'])
  async handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === KEYBOARD_KEYS.ARROW_RIGHT || event.key === KEYBOARD_KEYS.ENTER) {
      await this.nextStep();
    }

    if (
      event.key === KEYBOARD_KEYS.ARROW_LEFT &&
      this.kioskConfigStore.stepWidgets().some((sw) => sw.widget.widgetName !== WIDGET_NAMES.FORMS)
    ) {
      this.prevStep();
    }
  }

  private readonly onDestroy$ = new Subject();
  readonly timer$ = this.localStorageService.getItem<string>(LOCAL_STORAGE_KIOSK_REFRESH_TOKEN_EXPIRATION_KEY).pipe(
    switchMap((expiresAt) =>
      timer(STARTING_VALUE_TO_EMIT, INTERVAL_TO_EMIT_IN_MILLISECONDS).pipe(
        takeUntil(this.onDestroy$),
        filter(() => new Date(Number(expiresAt)) < new Date()),
        tap(() => {
          this.authStore.logout();
          this.onDestroy$.next(true);
          this.onDestroy$.complete();
        }),
      ),
    ),
  );

  ngOnInit(): void {
    if (this.kioskConfigStore.updateAvialable()) {
      document.location.reload();
    }

    this.resetAllStates();
  }

  /**
   * Performs checks on a step before navigting forward if all prereqs pass.
   *
   * Widgets don't depend on steps, and they execute in the order defined by configs.
   * The wizard mvoes onto the next step once all actionable widgets in a step passed validations.
   */
  async nextStep() {
    const stepWidgets = this.kioskConfigStore.stepWidgets;
    const nextStep = this.footerViewModel().nextStep;
    const canNavigate = await this.processCurrentStepWidgets(stepWidgets());

    if (canNavigate) {
      this.kioskConfigStore.setNextStep(nextStep);
    }
  }

  /**
   * Performs state cleanup before navigating back to step.
   */
  prevStep() {
    const started = this.kioskConfigStore.started;
    const { prevStep } = this.footerViewModel();
    if (!prevStep && started()) {
      this.kioskConfigStore.resetWorkflow();
    } else {
      let route = prevStep;
      const stepWidgets = this.kioskConfigStore.prevStepWidgets;

      for (const stepWidget of stepWidgets()) {
        if (
          stepWidget.widget.widgetName === WIDGET_NAMES.AUTHENTICATION ||
          stepWidget.widget.widgetName === WIDGET_NAMES.PATIENT_DETAILS
        ) {
          this.resetAllStates();
          // patient authentication is always required.
          // force re-auth when a patient navigates back to a step with one of these widgets
          // via back button or swip action on mobile
          route = STEP_ROUTES.AUTHENTICATION;
        }
      }

      this.kioskConfigStore.setPrevStep(route);
    }
  }

  /**
   * Resets all states and restarts from the workflow selector.
   */
  initWorkflow() {
    this.kioskConfigStore.resetWorkflow();
    this.resetAllStates();
  }

  /**
   * Resets the workflow state before clearing the user's authenticated session.
   */
  handleLogoutClick() {
    this.kioskConfigStore.resetWorkflowState();
    this.authStore.logout();
  }

  /**
   * Processes widgets on a current step. Widgets that pass validation can move onto the next step.
   *
   * @param stepWidgets - widgets on the current step
   * @returns boolean determining whether validation have passed
   */
  private async processCurrentStepWidgets(stepWidgets: StepWidget[]): Promise<boolean> {
    let validationsPassed = false;

    for (const stepWidget of stepWidgets) {
      if (stepWidget.widget.widgetName === WIDGET_NAMES.AUTHENTICATION) {
        // perform patient search
        await this.patientsSearchStore.validateAndSearchPatients();

        // pull from state after search or else state won't be updated
        const { selectedEntity: patient, duplicate } = this.patientsSearchStore;
        const { autoCount: patientId, mrn: patientMrn } = patient() ?? defaultPatient;
        const { atCorrectLocation, checkPatientAppointments } = this.appointmentsStore;

        // check for relevant appointments
        await checkPatientAppointments(patientId);

        // setup up the patient if they exist, aren't a dupe, and at the right location
        const canSetupPatient = Boolean(!duplicate() && patientId && atCorrectLocation());
        await this.setupPatient(patientMrn, canSetupPatient);

        // detemine navigation if patient validations pass
        validationsPassed = canSetupPatient;
      } else if (stepWidget.widget.widgetName === WIDGET_NAMES.PATIENT_DETAILS) {
        const patient = this.patientsSearchStore.selectedEntity();
        await this.patientDetailsStore.savePatientComments(patient);
        validationsPassed = true;
      } else if (stepWidget.widget.widgetName === WIDGET_NAMES.APPOINTMENT_DETAILS) {
        const patient = this.patientsSearchStore.selectedEntity();
        await this.patientDetailsStore.saveAppointmentComments(patient);
        validationsPassed = true;
      } else if (
        stepWidget.widget.widgetName === WIDGET_NAMES.UPLOAD_INDENTIFICATION_FRONT ||
        stepWidget.widget.widgetName === WIDGET_NAMES.UPLOAD_INDENTIFICATION_BACK ||
        stepWidget.widget.widgetName === WIDGET_NAMES.UPLOAD_INSURANCE_FRONT ||
        stepWidget.widget.widgetName === WIDGET_NAMES.UPLOAD_INSURANCE_BACK
      ) {
        this.filesStore.uploadFile();
        validationsPassed = false;
      } else if (
        stepWidget.widget.widgetName === WIDGET_NAMES.FORMS ||
        stepWidget.widget.widgetName === WIDGET_NAMES.BILLING
      ) {
        validationsPassed = true;
      }
    }

    return validationsPassed;
  }

  /**
   * Checks next button visibility on validations for specific steps.
   *
   * @returns boolean based on various widget validations
   */
  private isNextButtonVisible() {
    let validationsPassed = false;
    const stepWidgets = this.kioskConfigStore.stepWidgets();

    for (const stepWidget of stepWidgets) {
      if (
        stepWidget.widget.widgetName === WIDGET_NAMES.UPLOAD_INDENTIFICATION_FRONT ||
        stepWidget.widget.widgetName === WIDGET_NAMES.UPLOAD_INDENTIFICATION_BACK ||
        stepWidget.widget.widgetName === WIDGET_NAMES.UPLOAD_INSURANCE_FRONT ||
        stepWidget.widget.widgetName === WIDGET_NAMES.UPLOAD_INSURANCE_BACK
      ) {
        validationsPassed = this.filesStore.hasFile();
      } else if (stepWidget.widget.widgetName === WIDGET_NAMES.FORMS) {
        validationsPassed = Boolean(this.eformsCompletedStatus());
      } else if (
        !(
          stepWidget.widget.widgetName === WIDGET_NAMES.BILLING ||
          stepWidget.widget.widgetName === WIDGET_NAMES.CONFIRMATION
        )
      ) {
        validationsPassed = true;
      }
    }

    return validationsPassed;
  }

  /**
   * Sets up the patient once they are found.
   *
   * Validations include:
   * - Verifying appointments for the day
   * - Initializing upload metadata
   * - Starting realtime forms connections
   * - Starting the idle timeout process
   *
   * @param patientMrn - the patient's unique medical record identifier
   */
  private async setupPatient(patientMrn: string, canSetupPatient: boolean) {
    if (!canSetupPatient) {
      return;
    }

    await this.kioskConfigStore.removeCompletedSteps(patientMrn);
    await this.realtimeFormsStore.loadRealtimeForms();
    this.store.dispatch(
      RealtimeFormsActions.loadExtractedCredentials({ eformsToken: this.realtimeFormsStore.eformsToken() }),
    );
  }

  /**
   * Resets all states and ends all connections.
   */
  private resetAllStates() {
    this.patientsSearchStore.resetPatientSearchState();
    this.patientDetailsStore.resetPatientCommentsState();
    this.appointmentsStore.resetAppointentsState();
    this.filesStore.resetFileState();
    this.uploadWizardStore.resetUploadWizard();
    this.realtimeFormsStore.resetRealtimeFormsState();

    this.store.dispatch(RealtimeFormsActions.connectToRealtimeFormsChannelStopped());
  }
}
