import {
  AfterViewInit,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  inject,
  InjectionToken,
  input,
  OnDestroy,
  OnInit,
  output,
  signal,
  ViewChild,
} from '@angular/core';

import * as UC from '@uploadcare/file-uploader';
import { ActivityType } from '@uploadcare/file-uploader/abstract/ActivityBlock';
import '@uploadcare/file-uploader/web/uc-file-uploader-regular.min.css';

import { hasAllValues, UploadSecureSignature } from '@abbadox-monorepo/core-utils';
import { UPLOADCARE_CONFIG } from '@abbadox-monorepo/kiosk-core-http-client';

/** Default options for the file uploader component that can be overridden. */
export interface FileUploaderConfig {
  /**
   * Uploadcare context name.
   *
   * Serves as the bridge connection to other Uploadcare blocks on a page.
   */
  contextName: string;

  /**
   * Sources to allow uploads from.
   *
   * Sources are a comma separated list where the
   * default is 'local, url, camera, dropbox, gdrive'.
   */
  sources: string;

  /** The mode of uploader to render. */
  mode: 'regular' | 'inline' | 'minimal';

  /** The light or dark theme of the uploader. */
  theme: 'light' | 'dark';

  /** The starting action the uploader should initialize in. */
  startAction: ActivityType;

  /**
   * User deivce camera options.
   *
   * user - camera facing the user
   * environment - camera facing away from the user
   */
  cameraCapture: 'user' | 'environment' | '';

  /** Removes the "Provided by Uploadcare" message from the uploader's footer. */
  removeCopyright: boolean;

  /** Allow uploaded images only. */
  imgOnly: boolean;

  /** Provides a user confirmation before beginning uploads. */
  confirmUpload: boolean;
}

type ActivityChange = {
  previous: ActivityType | null;
  current: ActivityType | null;
};

/** Injection token that can be used to specify default image-loader options. */
export const FILE_UPLOADER_OPTIONS = new InjectionToken<FileUploaderConfig>('FileUploaderDefaultOptions', {
  factory: () => ({
    contextName: 'my-uploader',
    theme: 'light',
    startAction: 'upload-list',
    sources: 'local, url, camera, dropbox, gdrive',
    mode: 'regular',
    cameraCapture: 'user',
    removeCopyright: true,
    imgOnly: true,
    confirmUpload: false,
  }),
});

UC.defineComponents(UC);

@Component({
  selector: 'core-file-uploader',
  standalone: true,
  template: `
    <uc-config
      #config
      [attr.ctx-name]="_config().contextName"
      [attr.source-list]="_config().sources"
      [pubkey]="uploadcareConfig.pubKey"
      [confirmUpload]="_config().confirmUpload"
      [removeCopyright]="_config().removeCopyright"
      [imgOnly]="_config().imgOnly"
      [cameraCapture]="_config().cameraCapture"
    ></uc-config>

    @switch (_config().mode) {
      @case ('minimal') {
        <uc-file-uploader-minimal
          [attr.ctx-name]="_config().contextName"
          [class.uc-dark]="_config().theme === 'dark'"
          [class.uc-light]="_config().theme === 'light'"
        ></uc-file-uploader-minimal>
      }

      @case ('inline') {
        <uc-file-uploader-inline
          [attr.ctx-name]="_config().contextName"
          [class.uc-dark]="_config().theme === 'dark'"
          [class.uc-light]="_config().theme === 'light'"
        ></uc-file-uploader-inline>
      }

      @default {
        <uc-file-uploader-regular
          [attr.ctx-name]="_config().contextName"
          [class.uc-dark]="_config().theme === 'dark'"
          [class.uc-light]="_config().theme === 'light'"
        ></uc-file-uploader-regular>
      }
    }

    <uc-upload-ctx-provider #ctxProvider [attr.ctx-name]="_config().contextName"></uc-upload-ctx-provider>
  `,
  styleUrl: './file-uploader.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FileUploader implements OnInit, AfterViewInit, OnDestroy {
  readonly uploadcareConfig = inject(UPLOADCARE_CONFIG);
  private readonly defaultConfig = inject(FILE_UPLOADER_OPTIONS);

  readonly btnLocalOverride = input('Upload files');
  readonly metadata = input<Record<string, string>>();
  readonly secureParams = input<UploadSecureSignature>({ secureSignature: '', secureExpire: '' });

  /**
   * Config options for the file uploader.
   *
   * Settings matching global config will be overriden.
   */
  readonly config = input<Partial<FileUploaderConfig>>();
  _config = computed(() => ({ ...this.defaultConfig, ...this.config() }));

  /** Emits the files from an event emitted by the uploader. */
  // readonly filesChange = output<OutputFileEntry<'success'>[]>();
  readonly previewSrc = output<string>();
  readonly open = output<void>();
  readonly close = output<void>();
  readonly fileUploading = output<boolean>();
  readonly fileUploadSuccess = output<boolean>();
  readonly fileUploadFailed = output<UC.OutputError<UC.OutputFileErrorType>[]>();

  /** Grabs the context provider where uploader events are emitted and can be listened to.  */
  @ViewChild('ctxProvider', { static: true }) ctxProviderRef!: ElementRef<InstanceType<UC.UploadCtxProvider>>;

  /** Grabs the context provider where uploader events are emitted and can be listened to.  */
  @ViewChild('config', { static: true }) configRef!: ElementRef<InstanceType<UC.Config>>;

  /** Store reference to public API. */
  api!: UC.UploaderPublicApi;

  /** Track upload activity state. */
  activitySeq = signal<ActivityChange>({ previous: null, current: null });

  ngOnInit(): void {
    this.configRef.nativeElement.localeDefinitionOverride = {
      en: {
        'upload-files': this.btnLocalOverride(),
      },
    };

    const metadata = this.metadata();
    if (metadata) {
      this.setMetadata(metadata);
    }

    const secureParams = this.secureParams();
    if (hasAllValues(secureParams)) {
      this.setSecureParams(secureParams);
    }
  }

  ngAfterViewInit(): void {
    this.waitForApiReady().then(() => {
      this.api = this.ctxProviderRef.nativeElement.getAPI();
      this.ctxProviderRef.nativeElement.addEventListener('activity-change', this.handleActivityChange);
      this.ctxProviderRef.nativeElement.addEventListener('modal-open', this.handleModalOpenEvent);
      this.ctxProviderRef.nativeElement.addEventListener('modal-close', this.handleModalCloseEvent);
      this.ctxProviderRef.nativeElement.addEventListener('file-added', this.handlePreviewFile);
      this.ctxProviderRef.nativeElement.addEventListener('file-upload-progress', this.handleFileUploading);
      this.ctxProviderRef.nativeElement.addEventListener('file-upload-success', this.handleFileUploadSuccess);
      this.ctxProviderRef.nativeElement.addEventListener('file-upload-failed', this.handleFileUploadFailed);

      if (this._config().startAction) {
        this.setCurrentActivity(this._config().startAction);
      }
    });
  }

  ngOnDestroy() {
    this.ctxProviderRef.nativeElement.removeEventListener('activity-change', this.handleActivityChange);
    this.ctxProviderRef.nativeElement.removeEventListener('modal-open', this.handleModalOpenEvent);
    this.ctxProviderRef.nativeElement.removeEventListener('modal-close', this.handleModalCloseEvent);
    this.ctxProviderRef.nativeElement.removeEventListener('file-added', this.handlePreviewFile);
    this.ctxProviderRef.nativeElement.removeEventListener('file-upload-progress', this.handleFileUploading);
    this.ctxProviderRef.nativeElement.removeEventListener('file-upload-success', this.handleFileUploadSuccess);
    this.ctxProviderRef.nativeElement.removeEventListener('file-upload-failed', this.handleFileUploadFailed);
  }

  /**
   * Sets the metadata dynamically for the uploaded file if not provided on component init.
   * Metadata is a key-value pair that can be used to store additional information about the file.
   *
   * @param metadata - metadata to attach to the uploaded file.
   */
  setMetadata(metadata: Record<string, string>) {
    if (metadata) {
      this.configRef.nativeElement.metadata = metadata;
    }
  }

  /**
   * Sets the secure signature and expire time dynamically for the uploaded file if not provided on component init.
   * Secure signature is a token that allows you to upload files to your Uploadcare project securely.
   *
   * @param params - secure signature and expire time for the uploaded file.
   */
  setSecureParams(params: UploadSecureSignature) {
    if (params) {
      this.configRef.nativeElement.secureSignature = params.secureSignature;
      this.configRef.nativeElement.secureExpire = params.secureExpire;
      this.configRef.nativeElement.secureDeliveryProxy = `${this.uploadcareConfig.previewProxy}url={{previewUrl}}`;
    }
  }

  /**
   * Sets the current activity of the uploader.
   *
   * @example - start the uploader in camera capture mode.
   * setCurrentActivity('camera')
   */
  setCurrentActivity(activityType: ActivityType, params = undefined) {
    this.api.setCurrentActivity(activityType, params);
  }

  /**
   * Emit when upload modal is opened.
   *
   * NOTE: Only works in regular mode.
   */
  handleModalOpenEvent = () => {
    this.open.emit();
  };

  /**
   * Emit when upload modal is closed.
   *
   * NOTE: Only works in regular mode.
   */
  handleModalCloseEvent = () => {
    this.close.emit();
  };

  /**
   * Generated file created before uploading an image.
   *
   * @param file - image source to preview
   */
  handlePreviewFile = (e: UC.EventMap['file-added']) => {
    const file = e.detail.file;
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        // convert image file to base64 string and emit
        this.previewSrc.emit(String(reader.result));
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  /**
   * Skips auto-uploading a file using the built-in upload previewer.
   *
   * @param e - CustomEvent emitted by the upload provider
   */
  handleActivityChange = (e: UC.EventMap['activity-change']) => {
    this.activitySeq.update((activity) => ({
      ...activity,
      previous: this.activitySeq().current,
      current: e.detail.activity,
    }));

    if (this.activitySeq().current === 'upload-list' && this.activitySeq().previous === 'camera') {
      this.api.doneFlow();
    }
  };

  /**
   * Tracks the uploading state of the uploader.
   *
   * @param e - CustomEvent emitted by the upload provider
   */
  handleFileUploading = (e: UC.EventMap['file-upload-progress']) => {
    this.fileUploading.emit(e.detail.isUploading);
  };

  /**
   * Tracks the upload successul state of the uploader.
   *
   * @param e - CustomEvent emitted by the upload provider
   */
  handleFileUploadSuccess = (e: UC.EventMap['file-upload-success']) => {
    this.fileUploadSuccess.emit(e.detail.isSuccess);
    this.resetUploaderState();
  };

  /**
   * Tracks the upload failure state of the uploader.
   *
   * @param e - CustomEvent emitted by the upload provider
   */
  handleFileUploadFailed = (e: UC.EventMap['file-upload-failed']) => {
    this.fileUploadFailed.emit(e.detail.errors);
  };

  resetUploaderState() {
    this.api.removeAllFiles();
    this.setCurrentActivity(this._config().startAction);
  }

  /**
   * Detects whether API is loaded but only after the view has rendered.
   *
   * @returns boolean promise
   */
  private async waitForApiReady(): Promise<boolean> {
    let isResolved = false;

    return new Promise<boolean>((resolve, reject) => {
      this.ctxProviderRef.nativeElement.sub('*publicApi', (api: UC.UploaderPublicApi) => {
        if (isResolved) {
          reject(new Error('Uploadcare API nout found'));
          return;
        }

        if (api) {
          isResolved = true;
          resolve(true);
        }
      });
    });
  }
}
