import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class FormsService {
  form!: FormGroup;

  setForm(form: FormGroup) {
    this.form = form;
  }

  getForm() {
    return this.form;
  }

  validateFormControls() {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.controls[key].markAsTouched();
      this.form.controls[key].updateValueAndValidity();
    });
  }
}
