import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { withState, signalStore, withMethods, patchState, withComputed } from '@ngrx/signals';
import { EntityId, SelectEntityId, setAllEntities, updateEntity, withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { filter, pipe, tap } from 'rxjs';

import {
  withRequestStatus,
  withLogger,
  setLoading,
  setLoaded,
  setError,
  withSelectedEntity,
} from '@abbadox-monorepo/core-data-access';
import { IdleStatus, IdleStore } from '@abbadox-monorepo/core-idle';
import {
  KioskPatientSessionTimeoutDialog,
  KioskSessionTimeoutDialogData,
  PATIENT_SESSION_TIMEOUT_DIALOG_TRIGGERS,
} from '@abbadox-monorepo/kiosk-ui';
import { UploadWizardStore } from '@abbadox-monorepo/kiosk-upload-data-access';

import { initialKioskConfigurationsState } from './models/configs.model';
import { defaultStep } from './models/step.model';
import { WIDGET_NAMES } from './models/widget.model';
import { emptyWorkflow, Workflow } from './models/workflow.model';
import { WorkflowsHttpClientService } from './services/workflows-http-client.service';
import {
  setWorkflowStarted,
  setWorkflowInit,
  setWorkflowCompleted,
  withWorkflowStatus,
} from './with-workflow-status.feature';
import { parseConfigs, parseSkippedFeatures } from './workflows.parser';

const selectId: SelectEntityId<Workflow> = (workflow) => workflow.action.route;

export const KioskConfigurationsStore = signalStore(
  { providedIn: 'root' },
  withState(initialKioskConfigurationsState),
  withRequestStatus(),
  withWorkflowStatus(),
  withEntities<Workflow>(),
  withSelectedEntity(),
  // withRouteParams({ workflowName: (param) => param }),
  withLogger('kiosk config'),
  withComputed(({ selectedEntity, currentStepRoute }) => {
    const workflow = computed(() => selectedEntity() ?? emptyWorkflow);
    const allowableStatuses = computed(() =>
      workflow()
        .allowableStatuses.map((s) => s.statusId)
        .join(','),
    );
    const steps = computed(() => workflow().steps.entities);
    const totalSteps = computed(() => workflow().steps.total);
    const activeStep = computed(() => steps().find((step) => step.route === currentStepRoute()) ?? defaultStep);
    const stepsProgress = computed(() => Math.ceil((activeStep().sortOrder / totalSteps()) * 100));
    const stepTitle = computed(() => workflow().action.actionName);
    const stepName = computed(() => activeStep().stepName);
    const stepWidgets = computed(() => activeStep().stepWidgets);
    const nextStep = computed(() => steps().find((s) => s.sortOrder === activeStep().sortOrder + 1) ?? defaultStep);
    const nextStepRoute = computed(() => nextStep().route);
    const prevStep = computed(() => steps().find((s) => s.sortOrder === activeStep().sortOrder - 1) ?? defaultStep);
    const prevStepRoute = computed(() => prevStep().route);
    const prevStepWidgets = computed(() => prevStep().stepWidgets);
    const uploadIdentificationWidgetActive = computed(() =>
      stepWidgets().some((sw) => /identification/i.test(sw.widget.widgetName)),
    );
    const uploadInsuranceWidgetActive = computed(() =>
      stepWidgets().some((sw) => /identification/i.test(sw.widget.widgetName)),
    );
    const uploadIdBackEnabled = computed(() =>
      stepWidgets().some((sw) => sw.widget.widgetName === WIDGET_NAMES.UPLOAD_INDENTIFICATION_BACK),
    );
    const uploadInsuranceBackEnabled = computed(() =>
      stepWidgets().some((sw) => sw.widget.widgetName === WIDGET_NAMES.UPLOAD_INSURANCE_BACK),
    );
    const realtimeFormsStep = computed(() => stepWidgets().some((sw) => sw.widget.widgetName === WIDGET_NAMES.FORMS));
    const cofirmationStep = computed(() =>
      stepWidgets().some((sw) => sw.widget.widgetName === WIDGET_NAMES.CONFIRMATION),
    );
    const appointmentStatusChangeUponCompletion = computed(() => workflow().appointmentStatusChangeUponCompletion ?? 0);

    return {
      allowableStatuses,
      steps,
      totalSteps,
      activeStep,
      nextStep,
      nextStepRoute,
      prevStep,
      prevStepRoute,
      prevStepWidgets,
      stepTitle,
      stepName,
      stepsProgress,
      stepWidgets,
      uploadIdentificationWidgetActive,
      uploadInsuranceWidgetActive,
      uploadIdBackEnabled,
      uploadInsuranceBackEnabled,
      realtimeFormsStep,
      cofirmationStep,
      appointmentStatusChangeUponCompletion,
    };
  }),
  withMethods(
    (
      state,
      workflowsHttpClientService = inject(WorkflowsHttpClientService),
      router = inject(Router),
      dialog = inject(MatDialog),
      idleStore = inject(IdleStore),
      uploadWizardStore = inject(UploadWizardStore),
    ) => {
      function navigateToWorkflowSelector(): Promise<boolean> {
        return router.navigate(['/home']);
      }

      function setActiveStep(workflowRoute: EntityId | undefined, stepRoute: string | undefined): void {
        patchState(state, { selectedEntityId: workflowRoute, currentStepRoute: stepRoute });
      }

      function navigateToStep(workflowRoute: EntityId, stepRoute: string): void {
        router.navigate([`/${workflowRoute}/${stepRoute}`]);
      }

      // starts a workflow from a given step.
      function startWorkflow(workflowRoute: string, stepRoute: string): void {
        setActiveStep(workflowRoute, stepRoute);
        patchState(state, setWorkflowStarted);
        navigateToStep(workflowRoute, stepRoute);
      }

      function setNextStep(nextStep: string): void {
        const workflowRoute = state.selectedEntityId() ?? '';
        setActiveStep(workflowRoute, nextStep);
        navigateToStep(workflowRoute, nextStep);
      }

      function setPrevStep(prevStep: string): void {
        const workflowRoute = state.selectedEntityId() ?? '';
        setActiveStep(workflowRoute, prevStep);
        navigateToStep(workflowRoute, prevStep);
      }

      function resetWorkflowState(): void {
        setActiveStep(undefined, undefined);
        patchState(state, setWorkflowInit);
      }

      function resetWorkflow(): void {
        resetWorkflowState();
        navigateToWorkflowSelector().then(() => window.location.reload());
      }

      function completeWorkflow(): void {
        patchState(state, setWorkflowCompleted);
      }

      function updateVersion(updateAvialable: boolean) {
        patchState(state, { updateAvialable });
      }

      async function loadKioskConfigs(): Promise<void> {
        patchState(state, setLoading());

        try {
          const kioskConfigs = await workflowsHttpClientService.configsAsPromise();
          const { workflows, ...configs } = parseConfigs(kioskConfigs);
          patchState(state, configs, setAllEntities(workflows, { selectId }), setLoaded());
        } catch (err) {
          const error = err as HttpErrorResponse;
          patchState(state, setError(error.message));
        }
      }

      async function initConfigs(): Promise<void> {
        await loadKioskConfigs();
        idleStore.setupIdleTimeout(state.idleTimeoutInSeconds());

        if (state.init() || state.completed()) {
          resetWorkflowState();
        }
      }

      function startNormalTimeout() {
        idleStore.resetIdle();
        idleStore.setupIdleTimeout(state.idleTimeoutInSeconds());
        idleStore.startIdling();
      }

      function startRealtimeFormsTimeout() {
        idleStore.resetIdle();
        idleStore.setupIdleTimeout(state.formIdleTimeoutInSeconds());
        idleStore.startIdling();
      }

      async function removeCompletedSteps(patientMrn: string): Promise<void> {
        patchState(state, setLoading());

        await uploadWizardStore.verifyUploadedFeatures(patientMrn);

        const workflowId = state.selectedEntityId();

        if (!workflowId) {
          return;
        }

        const { idRecentlyUploaded, insuranceRecentlyUploaded } = uploadWizardStore;
        const validations = {
          idsUploaded: idRecentlyUploaded(),
          insuranceUploaded: insuranceRecentlyUploaded(),
        };

        patchState(
          state,
          updateEntity(
            { id: workflowId, changes: (workflow) => parseSkippedFeatures(workflow, validations) },
            { selectId },
          ),
          setLoaded,
        );
      }

      const showIdleModal = rxMethod<IdleStatus>(
        pipe(
          filter((idleStatus) => idleStatus === 'expired'),
          tap(() =>
            dialog
              .open<KioskPatientSessionTimeoutDialog, KioskSessionTimeoutDialogData>(KioskPatientSessionTimeoutDialog, {
                data: {
                  title: 'Idle Timeout',
                  maxTimeout: state.idleTimeoutInSeconds(),
                  sessionTimeout: state.sessionTimeoutInSeconds(),
                },
                disableClose: true, // stops outside click to close
              })
              .afterClosed()
              .subscribe((event) => {
                if (
                  event === PATIENT_SESSION_TIMEOUT_DIALOG_TRIGGERS.CLOSE_ICON ||
                  event === PATIENT_SESSION_TIMEOUT_DIALOG_TRIGGERS.CLOSE_BUTTON
                ) {
                  idleStore.startIdling();
                }

                if (event === PATIENT_SESSION_TIMEOUT_DIALOG_TRIGGERS.TIMEOUT) {
                  navigateToWorkflowSelector().then(() => window.location.reload());
                }
              }),
          ),
        ),
      );

      return {
        navigateToWorkflowSelector,
        setActiveStep,
        navigateToStep,
        startWorkflow,
        setNextStep,
        setPrevStep,
        resetWorkflow,
        resetWorkflowState,
        completeWorkflow,
        updateVersion,
        loadKioskConfigs,
        initConfigs,
        removeCompletedSteps,
        startNormalTimeout,
        startRealtimeFormsTimeout,
        showIdleModal,
      };
    },
  ),
);
