import { effect, isDevMode } from '@angular/core';

import { getState, signalStoreFeature, withHooks } from '@ngrx/signals';

/**
 * Debug logs store state when it changes.
 *
 * @param name - name of the feature store
 * @returns - signal feature
 */
export function withLogger(name: string) {
  return signalStoreFeature(
    withHooks({
      onInit(store) {
        effect(() => {
          if (isDevMode()) {
            const state = getState(store);
            console.log(`${name} state changed`, state);
          }
        });
      },
    }),
  );
}
