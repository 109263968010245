import { Component } from '@angular/core';

@Component({
  selector: 'ids-spinner',
  standalone: true,
  template: `<div
    class="animate-spin-slow border-primary inline-block h-8 w-8 rounded-[50%] border-[.25rem] border-r-transparent align-[-0.125rem]"
  >
    <span class="sr-only"></span>
  </div>`,
})
export class IdsSpinner {}
