export default {
  'locale-id': 'en',
  'social-source-lang': 'en',
  'upload-file': 'Upload file',
  'upload-files': 'Upload files',
  'choose-file': 'Choose file',
  'choose-files': 'Choose files',
  'drop-files-here': 'Drop files here',
  'select-file-source': 'Select file source',
  selected: 'Selected',
  upload: 'Upload',
  'add-more': 'Add more',
  cancel: 'Cancel',
  'start-from-cancel': 'Cancel',
  clear: 'Clear',
  'camera-shot': 'Shot',
  'upload-url': 'Import',
  'upload-url-placeholder': 'Paste link here',
  'edit-image': 'Edit image',
  'edit-detail': 'Details',
  back: 'Back',
  done: 'Done',
  ok: 'Ok',
  'remove-from-list': 'Remove',
  no: 'No',
  yes: 'Yes',
  'confirm-your-action': 'Confirm your action',
  'are-you-sure': 'Are you sure?',
  'selected-count': 'Selected:',
  'upload-error': 'Upload error',
  'validation-error': 'Validation error',
  'no-files': 'No files selected',
  browse: 'Browse',
  'not-uploaded-yet': 'Not uploaded yet...',
  file__one: 'file',
  file__other: 'files',
  error__one: 'error',
  error__other: 'errors',
  'header-uploading': 'Uploading {{count}} {{plural:file(count)}}',
  'header-failed': '{{count}} {{plural:error(count)}}',
  'header-succeed': '{{count}} {{plural:file(count)}} uploaded',
  'header-total': '{{count}} {{plural:file(count)}} selected',
  'src-type-local': 'From device',
  'src-type-from-url': 'From link',
  'src-type-camera': 'Camera',
  'src-type-draw': 'Draw',
  'src-type-facebook': 'Facebook',
  'src-type-dropbox': 'Dropbox',
  'src-type-gdrive': 'Google Drive',
  'src-type-gphotos': 'Google Photos',
  'src-type-instagram': 'Instagram',
  'src-type-flickr': 'Flickr',
  'src-type-vk': 'VK',
  'src-type-evernote': 'Evernote',
  'src-type-box': 'Box',
  'src-type-onedrive': 'Onedrive',
  'src-type-huddle': 'Huddle',
  'src-type-other': 'Other',
  'caption-from-url': 'Import from link',
  'caption-camera': 'Camera',
  'caption-draw': 'Draw',
  'caption-edit-file': 'Edit file',
  'file-no-name': 'No name...',
  'toggle-fullscreen': 'Toggle fullscreen',
  'toggle-guides': 'Toggle guides',
  rotate: 'Rotate',
  'flip-vertical': 'Flip vertical',
  'flip-horizontal': 'Flip horizontal',
  apply: 'Apply',
  brightness: 'Brightness',
  contrast: 'Contrast',
  saturation: 'Saturation',
  exposure: 'Exposure',
  gamma: 'Gamma',
  vibrance: 'Vibrance',
  warmth: 'Warmth',
  enhance: 'Enhance',
  original: 'Original',
  resize: 'Resize image',
  crop: 'Crop',
  'select-color': 'Select color',
  text: 'Text',
  draw: 'Draw',
  'cancel-edit': 'Cancel edit',
  'tab-view': 'Preview',
  'tab-details': 'Details',
  'file-name': 'Name',
  'file-size': 'Size',
  'cdn-url': 'CDN URL',
  'file-size-unknown': 'Unknown',
  'camera-permissions-denied': 'Camera access denied',
  'camera-permissions-prompt': 'Please allow access to the camera',
  'camera-permissions-request': 'Request access',
  'files-count-limit-error-title': 'Files count limit overflow',
  'files-count-limit-error-too-few': 'You’ve chosen {{total}} {{plural:file(total)}}. At least {{min}} {{plural:file(min)}} required.',
  'files-count-limit-error-too-many': 'You’ve chosen too many files. {{max}} {{plural:file(max)}} is maximum.',
  'files-max-size-limit-error': 'File is too big. Max file size is {{maxFileSize}}.',
  'has-validation-errors': 'File validation error ocurred. Please, check your files before upload.',
  'images-only-accepted': 'Only image files are accepted.',
  'file-type-not-allowed': 'Uploading of these file types is not allowed.',
  'some-files-were-not-uploaded': 'Some files were not uploaded.',
  'file-item-edit-button': 'Edit',
  'file-item-remove-button': 'Remove',
  'a11y-editor-tab-filters': 'Filters',
  'a11y-editor-tab-tuning': 'Tuning',
  'a11y-editor-tab-crop': 'Crop',
  'a11y-activity-header-button-close': 'Close',
  flip: 'Flip',
  mirror: 'Mirror',
  'a11y-cloud-editor-apply-filter': 'Apply {{name}} filter',
  'a11y-cloud-editor-apply-crop': 'Apply {{name}} operation',
  'a11y-cloud-editor-apply-tuning': 'Apply {{name}} tuning',
  finished: 'Finished',
  failed: 'Failed',
  uploading: 'Uploading',
  idle: 'Idle',
  'a11y-file-item-status': 'File {{fileName}} in status {{status}}'
};