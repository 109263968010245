import { Directive, HostListener, inject, input } from '@angular/core';
import { NgControl } from '@angular/forms';

import { endOfToday, isValid, parse } from 'date-fns';

const DATE_FNS_DEFAULT_FORMAT = 'MM/dd/yyyy';
const DEFAULT_MIN_DATE = new Date('1900-01-01');

@Directive({
  selector: 'input[idsDateMask]',
  exportAs: 'idsDateOnly',
  standalone: true,
})
export class IdsDateMask {
  public ngControl = inject(NgControl);

  format = input<string>(DATE_FNS_DEFAULT_FORMAT);
  minDate = input<Date>(DEFAULT_MIN_DATE);
  maxDate = input<Date>(endOfToday());

  @HostListener('ngModelChange', ['$event'])
  onKeydown(event: string) {
    this.onInputChange(event);
  }

  /**
   * Formats the input value to a date using the format param.
   *
   * @param event the input value
   * @param format the format of the date
   */
  onInputChange(event: string) {
    // Remove any invalid characters (non-digit and non-slash)
    let newVal = event.replace(/[^0-9/]/g, '');

    // Add slashes automatically
    if (newVal.length > 2 && newVal[2] !== '/') {
      newVal = newVal.slice(0, 2) + '/' + newVal.slice(2);
    }
    if (newVal.length > 5 && newVal[5] !== '/') {
      newVal = newVal.slice(0, 5) + '/' + newVal.slice(5);
    }

    if (newVal.length === 0) {
      newVal = '';
    } else {
      // Validate as user types
      newVal = this.validatePartialDate(newVal);
    }

    this.ngControl.valueAccessor?.writeValue(newVal);
  }

  private validatePartialDate(value: string): string {
    // Note: javascript's regex global flag cannot be used in a constant.
    // This is why we are using the regex pattern directly in the if statement.
    if (
      (value.length === 1 && /^[0-1]/g.test(value)) || // single-digit month
      (value.length < 3 && /^(0[1-9]|1[0-2])\/?/g.test(value)) || // month
      (value.length < 5 && /^(0[1-9]|1[0-2])\/?[0-3]/g.test(value)) || // single-digit day
      (value.length < 6 && /^(0[1-9]|1[0-2])\/?(0[1-9]|[12]\d|3[01])\/?/g.test(value)) || // month and day
      (value.length < 8 && /^(0[1-9]|1[0-2])\/?(0[1-9]|[12]\d|3[01])\/?[1-2]/g.test(value)) || // month, day, and millenium
      (value.length < 9 && /^(0[1-9]|1[0-2])\/?(0[1-9]|[12]\d|3[01])\/?(19|20)/g.test(value)) || // month, day, and century
      (value.length <= 10 && /^(0[1-9]|1[0-2])\/?(0[1-9]|[12]\d|3[01])\/?(19|20)\d{1,2}$/g.test(value)) // full date
    ) {
      return value;
    }

    return value.slice(0, value.length - 1);
  }

  private isValidDate(value: string): boolean {
    // match MM/DD/YYYY pattern
    if (!/^[0-1]?\d\/([0-2]?\d|3[0-1])\/\d{0,4}$/g.test(value)) {
      return false;
    }

    const date = this.parseDate(value);
    return isValid(date);
  }

  private parseDate(value: string): Date {
    return parse(value, DATE_FNS_DEFAULT_FORMAT, new Date());
  }

  private parseRangeDate(date: Date | null): Date | null {
    if (!date) {
      return null;
    }

    if (date instanceof Date) {
      return date;
    }

    return this.parseDate(date);
  }
}
