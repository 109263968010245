import { computed } from '@angular/core';

import { signalStoreFeature, withComputed, withState } from '@ngrx/signals';

export type IdleStatus = 'init' | 'idling' | 'active' | 'expired';
export type IdleStatusState = { idleState: IdleStatus };

/**
 * The `withIdleStatus` feature and updaters can be used to add the `idleState`
 * state slice, along with the `init`, `idling`, `active`, and `expired` computed signals
 * to a signal store.
 *
 * @example A `BooksStore` instance will contain the following properties and methods:
 * State signals from withRequestStatus feature:
 * - idleState: Signal<IdleStatus>
 * Computed signals from withIdleStatus feature:
 * - init: Signal<boolean>
 * - started: Signal<boolean>
 * - completed: Signal<boolean>
 *
 * @returns - signal store for idling status
 */
export function withIdleStatus() {
  return signalStoreFeature(
    withState<IdleStatusState>({ idleState: 'init' }),
    withComputed(({ idleState }) => ({
      init: computed(() => idleState() === 'init'),
      idling: computed(() => idleState() === 'idling'),
      active: computed(() => idleState() === 'active'),
      expired: computed(() => idleState() === 'expired'),
    })),
  );
}

// State updaters for modifying the workflow status
export function setIdleInit(): IdleStatusState {
  return { idleState: 'init' };
}

export function setIdleIdling(): IdleStatusState {
  return { idleState: 'idling' };
}

export function setIdleActive(): IdleStatusState {
  return { idleState: 'active' };
}

export function setIdleExpired(): IdleStatusState {
  return { idleState: 'expired' };
}
