export enum ERROR_MESSAGE_TYPE {
  'auth',
}

export type ErrorMessageTypes = keyof typeof ERROR_MESSAGE_TYPE;

export const ERROR_MESSAGES = new Map<
  ErrorMessageTypes,
  { heading?: string; body?: string; label?: string; messages?: string[] }
>([
  [
    'auth',
    {
      label: 'Authentication Failed',
      messages: ['The username or password you have entered is incorrect. Please verify and try again.'],
    },
  ],
]);
