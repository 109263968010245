import { InjectionToken } from '@angular/core';

export type ErrorMessages = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in string]: (args?: any) => string;
};

/** A set of validation error messages to register as pasrt of a form's input fields. */
export const DEFAULT_VALIDATION_ERROR_MESSAGES: ErrorMessages = {
  required: () => `Required field`,
  email: () => `Not a valid email`,
  minlength: ({ requiredLength }) => `The length should be at least ${requiredLength} characters`,
};

/** Injection provider options for input errors. */
export const VALIDATION_ERROR_MESSAGES = new InjectionToken(`Validation Messages`, {
  providedIn: 'root',
  factory: () => DEFAULT_VALIDATION_ERROR_MESSAGES,
});
